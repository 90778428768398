import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Navigation from './components/Navigation.js';


class App extends Component {

  render() {
    return (      
      <BrowserRouter>
          <Navigation />
      </BrowserRouter>
    );
  }
}
 
export default App;
