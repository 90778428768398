import React, { useState, useEffect } from 'react';
import Request from './Config/Request.js';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputLabel from '@material-ui/core/InputLabel';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
//import socketIOClient from "socket.io-client";
import Parameters from './Config/Parameters.js';
//const mySocket = socketIOClient(Parameters.H64_API);


export default function AllBlockchain(props) {

    let [blockchainId, setBlockchainId] = useState(props.match.params.blockchainId);
    let [errorCode, setErrorCode] = useState(null);
    let [errorMessage, setErrorMessage] = useState(null);
    let [transactions, setTransactions] = useState([]);
    let [blocks, setBlocks] = useState([]);
    let [totalNodes, setTotalNodes] = useState(null);
    let [totalSmartContracts, setTotalSmartContracts] = useState(null);
    let [expandedBlock, setExpandedBlock] = useState(null);
    let [expandedTx, setExpandedTx] = useState(null);

    useEffect(() => {
        try {
            const fetch = async () => {
                let blockchains = await setBlockchainToView();
                let transactions = [];
                for (let i = 0, l = blockchains.blocks.length; i < l; ++i)
                    for (let j = 0, k = blockchains.blocks[i].transactions.length; j < k; ++j)
                        transactions.push(blockchains.blocks[i].transactions[j]);
                setTransactions(transactions);
                setBlocks(blockchains.blocks);
                setTotalNodes(blockchains.totalNodes);
                setTotalSmartContracts(blockchains.totalSmartContracts);
            }
            fetch();

        } catch (err) {
            console.error(err);
        }

        //mySocket.on('disconnect', () => {
		//mySocket.removeAllListeners();
        //});

        //return () => mySocket.disconnect();

    }, []);

    /*
	mySocket.on("block", data => {
		let newBlocks = blocks;
		newBlocks.unshift(data.block);
		let newTransactions = transactions;
		for ( let i = 0 , l = data.block.transactions.length ; i < l ; ++i ){
			let ok = true;
			for ( let j = 0 , k = newTransactions.length ; j < k ; ++j )
				if ( newTransactions[j].hash === data.block.transactions[i].hash )
					ok = false;
			if ( ok )
				newTransactions.unshift(data.block.transactions[i]);
		}
		setBlocks(newBlocks);
		setTransactions(newTransactions);
	});
    */


    let optionsBlocks = {
        clickToEdit: true,
        clickToSelect: true,
        onRowClick: (row) => {
            setExpandedBlock(row);
        }
    };


    let optionsTx = {
        clickToEdit: true,
        clickToSelect: true,
        onRowClick: (row) => {
            setExpandedTx(row);
        }
    };

    let hideExpandedBlock = () => {
        setExpandedBlock(null);
    }

    let hideExpandedTx = () => {
        setExpandedTx(null);
    }

    let setBlockchainToView = async (blockchain) => {
        let logs = await Request.h64HttpRequest("GET", "/getLatestBlockchainLogs/" + blockchainId, {});
        return logs.logs;
    }

    let momentBlockCreationDate = (cell, row) => {
        return <>{moment(row.timestamp / 1000000).format("L HH:mm:ss")}</>
    }

    let getBlockTimestamp = (block) => {
        for (let i = 0, l = blocks.length; i < l; ++i)
            if (Number(blocks[i].number) === Number(block))
                return blocks[i].timestamp;
    }

    let momentTransactionCreationDate = (cell, row) => {
        if (row)
            return <>{moment(getBlockTimestamp(row.blockNumber) / 1000000).format("L HH:mm:ss")}</>
        return <></>
    }

    return (
        <>

            <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                <h1 className="h2">Blockchains</h1>
            </div>

            <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">
                <div className="row justify-content-center">
                    <div className="col-sm-4">
                        Last block number :
                            {
                            blocks && blocks.length > 0 &&
                            <>{blocks[0].number + " ( " + moment(blocks[0].timestamp / 1000000).fromNow() + " ) "}</>
                        }
                    </div>
                    <div className="col-sm-4">
                        Total nodes :
                            {
                            totalNodes &&
                            <>{totalNodes}</>
                        }
                    </div>
                    <div className="col-sm-4">
                        Total smart contracts :
                            {
                            totalSmartContracts &&
                            <>{totalSmartContracts}</>
                        }
                    </div>
                </div>
            </div>

            <div className={"container mt-5 mb-5" + (errorCode === null && errorMessage === null ? " d-none" : "")}>
                <div className="alert alert-danger" role="alert">{errorMessage}</div>
            </div>

            <BootstrapTable data={blocks} keyField='hash' striped hover pagination search options={optionsBlocks} >
                <TableHeaderColumn dataField='number' dataSort={true} >Block Nr.</TableHeaderColumn>
                <TableHeaderColumn dataField='hash' dataSort={true}>Hash</TableHeaderColumn>
                <TableHeaderColumn dataFormat={momentBlockCreationDate} >Creation date</TableHeaderColumn>
            </BootstrapTable>
            <BootstrapTable data={transactions} keyField='hash' striped hover pagination search options={optionsTx} >
                <TableHeaderColumn dataField='hash' dataSort={true} >Hash</TableHeaderColumn>
                <TableHeaderColumn dataField='from' dataSort={true}>From</TableHeaderColumn>
                <TableHeaderColumn dataFormat={momentTransactionCreationDate} >Creation date</TableHeaderColumn>
            </BootstrapTable>

            {
                expandedBlock &&
                <Modal show={expandedBlock} onHide={hideExpandedBlock}>
                    <Modal.Header closeButton>
                        <Modal.Title>Block ID #{expandedBlock._id}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="overflow-wrap-anywhere" >
                        <p>Nonce : {expandedBlock.nonce}</p>
                        <p>Transaction index : {expandedBlock.transactionIndex}</p>
                        <p>Parent Hash : {expandedBlock.parentHash}</p>
                        <p>SHA 3 Uncles : {expandedBlock.sha3Uncles}</p>
                        <p>Miner : {expandedBlock.miner}</p>
                        <p>State root : {expandedBlock.stateRoot}</p>
                        <p>Transactions root : {expandedBlock.transactionsRoot}</p>
                        <p>Receipts root : {expandedBlock.receiptsRoot}</p>
                        <p>Logs bloom : {expandedBlock.logsBloom}</p>
                        <p>Difficulty : {expandedBlock.difficulty}</p>
                        <p>Total difficulty : {expandedBlock.totalDifficulty}</p>
                        <p>Total transactions : {expandedBlock.transactions.length}</p>
                        <div className="mt-5 mb-5">
                            <p>Transactions : </p>
                            <BootstrapTable data={expandedBlock.transactions} keyField='hash' striped hover pagination search options={optionsTx} >
                                <TableHeaderColumn dataField='hash' dataSort={true} >Hash</TableHeaderColumn>
                                <TableHeaderColumn dataField='from' dataSort={true}>From</TableHeaderColumn>
                                <TableHeaderColumn dataFormat={momentTransactionCreationDate} >Creation date</TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                        <p>Uncles : {String(JSON.stringify(expandedBlock.uncles)).slice(1, -1)}</p>
                        <p>Size : {expandedBlock.size}</p>
                        <p>Number : {expandedBlock.number}</p>
                        <p>Gas limit : {expandedBlock.gasLimit}</p>
                        <p>Gas used : {expandedBlock.gasUsed}</p>
                        <p>Timestamp : {expandedBlock.timestamp}</p>
                        <p>Extra data : {expandedBlock.extraData}</p>
                        <p>Mix hash : {expandedBlock.mixHash}</p>
                        <p>Nonce : {expandedBlock.nonce}</p>
                        <p>Hash : {expandedBlock.hash}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="float-left" onClick={hideExpandedBlock}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            }


            {
                expandedTx &&
                <Modal show={expandedTx} onHide={hideExpandedTx}>
                    <Modal.Header closeButton>
                        <Modal.Title>Transaction ID #{expandedTx._id}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="overflow-wrap-anywhere" >
                        <p>Block hash : {expandedTx.blockHash}</p>
                        <p>Block number : {expandedTx.blockNumber}</p>
                        <p>From : {expandedTx.from}</p>
                        <p>Gas : {expandedTx.gas}</p>
                        <p>Gas price : {expandedTx.gasPrice}</p>
                        <p>Hash : {expandedTx.hash}</p>
                        <p>Input : {expandedTx.input}</p>
                        <p>Nonce : {expandedTx.nonce}</p>
                        <p>To : {expandedTx.to}</p>
                        <p>Transaction index : {expandedTx.transactionIndex}</p>
                        <p>Value : {expandedTx.value}</p>
                        <p>V : {expandedTx.v}</p>
                        <p>R : {expandedTx.r}</p>
                        <p>S : {expandedTx.s}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="float-left" onClick={hideExpandedTx}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            }


        </>
    );
}
