import React, { Component } from 'react';
import Parameters from './Config/Parameters.js';

class ForgotPassword extends Component {

    constructor(props) {
        sessionStorage.clear();
        super(props);
        this.state = { email : "" , errorCode : null , errorMessage : null };
        this.handleEmail = this.handleEmail.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }

    handleEmail(event) {
        this.setState({email: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        let params = { "email":this.state.email };
        let xhr = new XMLHttpRequest();
        xhr.open("PATCH", Parameters.API_URL + "/resetPassword", true);
        xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
        xhr.setRequestHeader("Content-Type","application/json");
        xhr.onload = function (e) {
          if (xhr.readyState === 4) {
            let json_obj = JSON.parse(xhr.responseText);
            if ( typeof json_obj.errorCode !== "undefined" && typeof json_obj.errorMessage !== "undefined" ){
                this.setState({errorCode: json_obj.errorCode});
                this.setState({errorMessage: json_obj.errorMessage});
                if ( json_obj.errorMessage === null && json_obj.errorCode === null ){
                    sessionStorage.setItem("lastOperation.errorCode",null);
                    sessionStorage.setItem("lastOperation.errorMessage",null);
                    sessionStorage.setItem("lastOperation.name","Change email");
                    document.location.href = "/SignIn";
                }
            }
        }
      }.bind(this);
        xhr.onerror = function (e) {
              console.error(xhr.statusText);
        };
        xhr.send(JSON.stringify(params));
    }  

    render(){
        return (
            <div className="ml-auto mr-auto mt-5">
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"></link>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-md-offset-4">
                            <div className="panel panel-default">
                                <div className="panel-body">
                                    <div className="text-center">
                                        <h3><i className="fa fa-lock fa-4x"></i></h3>
                                        <h2 className="text-center">Forgot Password?</h2>
                                        <p>You can reset your password here.</p>
                                        <div className="panel-body">

                                            <form onSubmit={this.handleSubmit} className="form">

                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="glyphicon glyphicon-envelope color-blue"></i></span>
                                                        <input placeholder="Your email address" value={this.state.email} onChange={this.handleEmail} className="form-control" type="email"/>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <input className="btn btn-outline-primary rounded-pill" value="Reset Password" type="submit"/>
                                                </div>

                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default ForgotPassword;
