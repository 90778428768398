import React, { useState } from 'react';
import Request from './Config/Request.js';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Parameters from './Config/Parameters.js';

function SmartContractCode(props) {

    const smartContracts = typeof props.smartContracts !== "undefined" ? props.smartContracts : "";
    const classInjector = typeof props.classInjector !== "undefined" ? props.classInjector : null;
    const userId = typeof props.templateId !== "undefined" ? props.templateId : "";
    const bundleId = typeof props.bundleId !== "undefined" ? props.bundleId : "";
    const creationDate = typeof props.creationDate !== "undefined" ? props.creationDate : "";
    let [filesCode, setFilesCode] = useState([]);
    let [filesPath, setFilesPath] = useState([]);

    let showSmartContractCode = async () => {
        try{
            let filesPath = [], filesCode = [], performedRequests = [];
            for (let i = 0, l = smartContracts.length; i < l; ++i) {
                let performedRequest = "/templates/" + userId + "/" + creationDate + "_" + smartContracts[i].file;
                console.log("Get code from : " + performedRequest);
                if (performedRequests.indexOf(performedRequest) === -1) {
                    let getSmartContractCode = await Request.h64HttpRequest("GET", performedRequest, {});
                    if ( typeof getSmartContractCode === "string" ){
                        performedRequests.push(performedRequest);
                        filesPath.push(Parameters.H64_API + performedRequest);
                        filesCode.push(getSmartContractCode.split("\n"));
                    }
                }
            }
            setFilesPath(filesPath);
            setFilesCode(filesCode);
        }catch(err){
            console.error(err);
        }
    }

    let hideCode = () => {
        setFilesCode([]);
    }

    return (
        <>
            <Button className={classInjector ? classInjector : ""} onClick={showSmartContractCode} >
                <i className="far fa-file-code"></i>
            </Button>
            {
                filesCode.length > 0 &&
                <Modal show={filesCode.length > 0} size="lg" onHide={hideCode}>
                    <Modal.Body>

                        <div className="container mt-5 mb-5" >
                            <div className="mt-5 mb-3">
                                <h4>
                                    {props.bundleTitle}
                                </h4>
                                <br/>
                                <p>
                                    {props.bundleDescription}
                                </p>
                                <hr/>
                            </div>
                        </div>

                        {filesCode && filesCode.map((code, cid) =>

                            <div className="container mt-5 mb-5" key={cid} >

                                <div className="mt-5 mb-3">
                                    <div className="d-inline">
                                        <h3>{props.smartContracts[cid].name}</h3>
                                        <a className="btn btn-outlined-dark text-dark" href={filesPath[cid]} download={filesPath[cid]} >
                                            <i className="fas fa-download" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>

                                <code>
                                    <div>
                                        {
                                            code.map((line, lineId) =>
                                                <div key={lineId} >
                                                    {
                                                        line && line.length > 0 &&
                                                        <>
                                                            {line}<br />
                                                        </>
                                                    }
                                                </div>
                                            )
                                        }
                                       
                                    </div>
                                </code>

                            </div>

                        )}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="float-left" onClick={hideCode}>Close</Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}

export default SmartContractCode;
