import React, { useState } from 'react';
import Request from './Config/Request.js';
import { useHistory } from "react-router-dom";

function SignIn() {
    
    sessionStorage.clear();
    let [errorCode,setErrorCode] = useState(null);
    let [errorMessage,setErrorMessage] = useState(null);
    let [email,setEmail] = useState("");
    let [password,setPassword] = useState("");
    let history = useHistory();

    let handleSubmit = async () => {
        if ( password.length === 0 || email.length === 0) {
            setErrorCode(404);
            setErrorMessage("Merci de compléter tous les champs.");
            return;
        }

        let login = await Request.httpRequest("PUT","/loginAdmin",{
            email : email ,
            password : password 
        });
        if ( login ){
            if (typeof login.errorCode !== "undefined" && typeof login.errorMessage !== "undefined") {
                setErrorCode(login.errorCode);
                setErrorMessage(login.errorMessage);
                console.log("Login response : " + JSON.stringify(login));
                let user;
                if ( login.errorCode === null && login.errorMessage === null ){
                    user = {
                        password : login.user.password ,
                        passwordRaw : password ,
                        h64AccountID : login.user.h64AccountID ,
                        key : login.user.key ,
                        applications : login.user.applications ,
                        blockchainAccounts : login.user.blockchainAccounts ,
                        email : email ,
                    };
                    sessionStorage.setItem('user',JSON.stringify(user));
                    window.location.href = "/home";
                }
                await Request.httpRequestLog(typeof user !== "undefined" ? user._id : undefined,"SignIn",login.errorCode === null && login.errorMessage === null ? "Success" : "Error",email,password);
            }
        }

    }

        return (
            <>
                <div className="login-title mt-5 mb-5 text-center">Administration</div>
                <div className="container mt-5 mb-5">
                    <form className="form-sign-in" >
                        <div className="form-group">
                            <label className="control-label" >E-mail</label>
                            <div className="input-group">
                                <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label" >Mot de passe</label>
                            <div className="input-group">
                                <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                        </div>
                        <button type="button" onClick={handleSubmit} className="btn-form-sumbit mt-4 mb-4" >Envoyer</button>

                        <div className="form-group">
                            <p className="text-right" ><a className="card-link" href="/forgotPassword">Mot de passe oublié ?</a></p>
                        </div>

                    </form>
                </div>
                <div className={"container mt-5 mb-5" + ( errorCode === null && errorMessage === null ? " d-none" : "")}>
                    <div className="alert alert-danger" role="alert">{errorMessage}</div>
                </div>
            </>
        );

}

export default SignIn;
