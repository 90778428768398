import React from 'react';

function Azure() {
    return (
        <>
            <h5>Create a blockchain</h5>
            <p>Using Azure</p>
        </>
    );
}
 
export default Azure;