import React , { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputLabel from '@material-ui/core/InputLabel';
import Button from 'react-bootstrap/Button';

function SmartContractConstructorModal(props) {

    let [contracts,setContracts] = useState([]);

    let hideDeploySC = () => {
        setContracts([]);
    }

    let setTheContracts = () => {
        setContracts(props.contracts || []);
    }

    return (
        <>
            <Button variant="primary" onClick={setTheContracts} ><i className="fas fa-globe"></i></Button>
            <Modal show={contracts.length > 0} onHide={hideDeploySC}>
                <Modal.Header closeButton>
                    <Modal.Title>Constructor Parameters</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <Form.Group className="mt-5" >
                    <div className="mt-3">
                    {
                        contracts.map((ct,ctid) => 
                            <div key={ctid}>
                                {
                                    ct.constructorParameters.map((cstr,cstrid) => 
                                    <div className="form-row" key={cstrid} >
                                        <div className="col-4">
                                            <InputLabel className="text-dark" >Code Name</InputLabel>
                                            <input type="text" value={cstr.codeName} className="form-control" readOnly />
                                        </div>
                                        <div className="col-4">
                                            <InputLabel className="text-dark" >Display Name</InputLabel>
                                            <input type="text" value={cstr.displayName} className="form-control" readOnly />
                                        </div>
                                        <div className="col-4">
                                            <InputLabel className="text-dark" >Value</InputLabel>
                                            <input type="text" value={cstr.value} className="form-control" readOnly />
                                        </div>
                                    </div>   
                                )
                                }
                            </div>
                        )
                    }
                    </div>
                </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className="float-left" onClick={hideDeploySC}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SmartContractConstructorModal;
