import React, { useState } from 'react';
import Request from '../../Config/Request.js';
import { useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import InputLabel from '@material-ui/core/InputLabel';
import Button from 'react-bootstrap/Button';

function AWS(props) {

    let [errorCode,setErrorCode] = useState(null);
    let [errorMessage,setErrorMessage] = useState(null);
    let [user,setUser] = useState(JSON.parse(sessionStorage.getItem('user')));
    let [blockchain,setBlockchain] = useState({ name : "" , RPCPort : 22000 , listeningPort : 22001 , constellationPort : 22002 , raftPort : 22003 , nodeManagerPort : 22004 , wsPort : 22005 , isPublic : false });
    let history = useHistory();

    let createBlockchain = async () => {
        try{
            let create = await Request.h64HttpRequest("POST","/blockchain/create/aws",blockchain);
            if ( create.errorCode !== undefined && create.errorMessage !== undefined ){
                setErrorCode(create.errorCode);
                setErrorMessage(create.errorMessage);
                if ( create.errorCode === null && create.errorMessage === null ){
                    /* Execute code on success response */
                    history.push("/blockchains");
                }
            }
        }catch(err){
            console.error(err);
        }
    }


    return (
        <>
            <h5>Create a blockchain</h5>
            <p>Using AWS</p>

            {/* To remove this checker when OVH will be working again + perform debugs */}
            {
                props.blockchainReceived ? (
                    <>
                        <h5 className="mt-5" >
                            General informations
                        </h5>
                        <hr/>

                        <Form.Group className="mt-5">
                            <InputLabel className="text-dark" >Name</InputLabel>
                            <Form.Control value={blockchain.name} onChange={(e) => setBlockchain({...blockchain, name : e.target.value }) } />
                        </Form.Group>

                        <Form.Group>
                            <InputLabel className="text-dark" >WS port</InputLabel>
                            <Form.Control value={blockchain.wsPort} onChange={(e) => setBlockchain({...blockchain, wsPort : e.target.value }) } />
                        </Form.Group>



                        <h5 className="mt-5" >
                            Genesis node
                        </h5>
                        <hr/>

                        <Form.Group>
                            <InputLabel className="text-dark" >RPC port</InputLabel>
                            <Form.Control value={blockchain.RPCPort} onChange={(e) => setBlockchain({...blockchain, RPCPort : Number(e.target.value) , listeningPort : Number(e.target.value) + 1 , constellationPort : Number(e.target.value) + 2 , raftPort : Number(e.target.value) + 3 , nodeManagerPort : Number(e.target.value) + 4 , wsPort : Number(e.target.value) + 5 })} />
                        </Form.Group>

                        <Form.Group>
                            <InputLabel className="text-dark" >Listening port</InputLabel>
                            <Form.Control value={blockchain.listeningPort} onChange={(e) => setBlockchain({...blockchain, listeningPort : Number(e.target.value) }) } />
                        </Form.Group>

                        <Form.Group>
                            <InputLabel className="text-dark" >Constellation port</InputLabel>
                            <Form.Control value={blockchain.constellationPort} onChange={(e) => setBlockchain({...blockchain, constellationPort : Number(e.target.value) }) } />
                        </Form.Group>

                        <Form.Group>
                            <InputLabel className="text-dark" >Raft port</InputLabel>
                            <Form.Control value={blockchain.raftPort} onChange={(e) => setBlockchain({...blockchain, raftPort : Number(e.target.value) }) } />
                        </Form.Group>

                        <Form.Group>
                            <InputLabel className="text-dark" >Node manager port</InputLabel>
                            <Form.Control value={blockchain.nodeManagerPort} onChange={(e) => setBlockchain({...blockchain, nodeManagerPort : Number(e.target.value) }) } />
                        </Form.Group>



                        <h5 className="mt-5" >
                            Blockchain visibility
                        </h5>
                        <hr/>

                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" checked={blockchain.isPublic} onChange={(e) => setBlockchain({...blockchain, isPublic : !blockchain.isPublic }) } label="Free to use" />
                        </Form.Group>



                        <div className="row mt-5 text-center justify-content-center">
                            <Button variant="success" className="text-light" onClick={createBlockchain} >Create</Button>
                        </div>           
                    </>
                ) : (
                    <>
                    </>
                )
            }
        </>
    );
}
 
export default AWS;