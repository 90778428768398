import React , { useState , useEffect } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { Link } from 'react-router-dom';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Request from './Config/Request.js';
import Parameters from './Config/Parameters.js';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputLabel from '@material-ui/core/InputLabel';
import Button from 'react-bootstrap/Button';
import moment from 'moment';

function BlockchainInfos(props) {
    let [blockchainId,setBlockchainId] = useState(props.match.params.blockchainId);
    let [smartContracts,setSmartContracts] = useState([]);
    let [errorCode,setErrorCode] = useState(null);
    let [errorMessage,setErrorMessage] = useState(null);
    let [smartContract,setSmartContract] = useState(null);
    let [isEditing,setEditing] = useState(false);
    let [isDeleting,setDeleting] = useState(false);
    let [isDeploying,setDeploying] = useState(false);
    let [user,setUser] = useState(JSON.parse(sessionStorage.getItem('user')));
    let [deployResult,setDeployResult] = useState(null);
    let [coinbaseIndex,setCoinbaseIndex] = useState(0);
    let [coinbasePassword,setCoinbasePassword] = useState("");
    let [initialSupply,setInitialSupply] = useState(undefined);
    let [tokenName,setTokenName] = useState(undefined);
    let [tokenSymbol,setTokenSymbol] = useState(undefined);
    let [contractOwner,setContractOwner] = useState(user.accountAddress);
    let [contractOwnerPrivateKey,setContractOwnerPrivateKey] = useState(user.privateKey);
    let [typeToDeploy,setTypeToDeploy] = useState(null);
    let [addressVouchersContract,setAddressVoucherContract] = useState(null);

    let getSmartContracts = async () => {
        let smartContracts = await Request.h64HttpRequest("GET","/getBlockchainSmartContracts/" + blockchainId,{});
        return smartContracts.smartContracts || [];
    }

    const options = {
        clickToEdit: true,
        clickToSelect: true,
    };

    let hideDeleteModal = () => {
        setDeleting(false);
        setSmartContract(null);
    }

    useEffect( () => {
        try{
            const fetch = async () => {
                let contracts = await getSmartContracts();
                setSmartContracts(contracts);
            }
            fetch();
        }catch(err){
            console.error(err);
        }
    },[]);

    let edit = (row) => {
        setSmartContract(row);
        setEditing(true);
    }

    let deleteSC = (row) => {
        setSmartContract(row);
        setDeleting(true);
    }

    let updateSmartContract = async () => {
        try{
            console.log("The update smart contract ... ");
            let update = await Request.h64HttpRequest("PATCH","/updateSmartContract",{ contractId : smartContract._id , type : smartContract.type , subtype : smartContract.subtype });
            setErrorCode(update.errorCode);
            setErrorMessage(update.errorMessage);
            console.log("Update status : " + JSON.stringify(update));
            if ( update.errorCode === null && update.errorMessage === null ){
                let contracts = smartContracts;
                for ( let i = 0 , l = contracts.length ; i < l ; ++i )
                    if ( smartContract._id === contracts[i]._id ){
                        contracts[i] = smartContract;
                        break;
                    }
                setSmartContracts(contracts);
                setEditing(false);
                setSmartContract(null);
            }
        }catch(err){
            console.error(err);
        }
    }

    let hideEditModal = () => {
        setEditing(false);
        setSmartContract(null);
    }

    let deploySC = (row) => {
        setSmartContract(row);
        setDeploying(true);
        setTypeToDeploy(row.type);
    }

    let hideDeployModal = () => {
        setDeploying(false);
        setSmartContract(null);
        setTypeToDeploy(null);
    }

    let operations = (cell, row) => {
        return <>
        
            <Button variant="danger" onClick={() => deleteSC(row)} ><i className="fa fa-trash"></i></Button>
            <Button variant="warning" className="ml-1" onClick={() => edit(row)} ><i className="fa fa-edit text-light"></i></Button>
            <Button variant="primary" onClick={() => deploySC(row)} className="ml-1"><i className="fa fa-arrow-circle-up"></i></Button>
        </>;
    }

    let deleteSmartContract = async () => {
        try{
            let deleteSC = await Request.httpRequest("DELETE","/deleteSmartContract/" + smartContract._id,{});
            setErrorCode(deleteSC.errorCode);
            setErrorMessage(deleteSC.errorMessage);
            if ( deleteSC.errorCode === null && deleteSC.errorMessage === null ){
                let contracts = [];
                for ( let i = 0 , l = smartContracts.length ; i < l ; ++i )
                    if ( smartContracts[i]._id !== smartContract._id )
                        contracts.push(smartContracts[i]);
                setSmartContracts(contracts);
                setDeleting(false);
                setSmartContract(null);
            }
        }catch(err){
            console.error(err);
        }
    }

    let getBlockchainCreationDate = (cell, row) => {
        return <>{moment(row.creationDate).format("L HH:mm:ss")}</>
    }

    let deploy = async () => {
        try{
            let urlToDeploy = "";
            let reqBodyToSend = {};
            let methodToAccess = "";
            switch(typeToDeploy){
                case "ERC20":
                    urlToDeploy = Parameters.ERC20_DEPLOY_URL;
                    reqBodyToSend = { 
                        password : user.passwordRaw , 
                        accountID : user.h64AccountID , 
                        initialSupply : initialSupply , 
                        coinbaseIndex : coinbaseIndex ,
                        coinbasePassword : coinbasePassword ,
                        tokenName : tokenName , 
                        tokenSymbol : tokenSymbol , 
                        contractOwner : contractOwner , 
                        contractOwnerPrivateKey : contractOwnerPrivateKey ,
                        blockchainId : blockchainId
                    };
                    methodToAccess = "POST";
                    break;
                case "vouchers":
                    urlToDeploy = Parameters.VOUCHERS_DEPLOY_URL;
                    reqBodyToSend = { 
                        userId : user.h64AccountID , 
                        coinbaseIndex : coinbaseIndex ,
                        coinbasePassword : coinbasePassword ,
                        blockchainId : blockchainId
                    };
                    methodToAccess = "POST";
                    break;
                case "books":
                    urlToDeploy = Parameters.BOOKS_DEPLOY_URL;
                    reqBodyToSend = { 
                        userId : user.h64AccountID , 
                        coinbaseIndex : coinbaseIndex ,
                        coinbasePassword : coinbasePassword ,
                        addressVouchersContract : addressVouchersContract ,
                        blockchainId : blockchainId
                    };
                    methodToAccess = "POST";
                break;
            }
            let deploySC = await Request.h64HttpRequest(methodToAccess,urlToDeploy,reqBodyToSend);
            setErrorCode(deploySC.errorCode);
            setErrorMessage(deploySC.errorMessage);
            if ( deploySC.errorCode === null && deploySC.errorMessage === null )
                setDeployResult(deploySC.result);
            else
                hideDeployModal();
        }catch(err){
            console.error(err);
        }
    }

        return (
            <>

                <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                    <h1 className="h2">
                        Smart Contracts Library
                        <Link to="/mySmartContracts" role="button" className="float-right btn btn-primary text-light mt-2 mr-2">My Smart Contracts</Link>
                    </h1>
                </div>

                <div className={"container mt-5 mb-5" + ( errorCode === null && errorMessage === null ? " d-none" : "")}>
                    <div className="alert alert-danger" role="alert">{errorMessage}</div>
                </div>

                <BootstrapTable data={smartContracts} keyField='_id' striped hover pagination search options={ options } >
                    <TableHeaderColumn dataField='_applicationId' dataSort={ true } >Application Id</TableHeaderColumn>
                    <TableHeaderColumn dataField='type' dataSort={ true } >Type</TableHeaderColumn>
                    <TableHeaderColumn dataField='address' dataSort={ true }>Address</TableHeaderColumn>
                    <TableHeaderColumn dataFormat={getBlockchainCreationDate} dataField='creationDate' dataSort={ true }>Creation Date</TableHeaderColumn>
                    <TableHeaderColumn dataFormat={operations} >Operations</TableHeaderColumn>
                </BootstrapTable>

                {
                    smartContract && 
                    <>
                        <Modal show={isEditing} onHide={hideEditModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Smart Contract ID #{smartContract._id}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                            <Form.Group>
                                <InputLabel className="text-dark" >Type</InputLabel>
                                <Form.Control value={smartContract.type} onChange={(e) => setSmartContract({...smartContract, type : e.target.value }) } />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >Subtype</InputLabel>
                                <Form.Control value={smartContract.subtype} onChange={(e) => setSmartContract({...smartContract, subtype : e.target.value }) } />
                            </Form.Group>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className="float-left" onClick={hideEditModal}>Cancel</Button>
                                <Button variant="warning" className="text-light" onClick={updateSmartContract} >Update</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={isDeleting} onHide={hideDeleteModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Smart Contract ID #{smartContract._id}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                Are you sure you want to delete this smart contract ?

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className="float-left" onClick={hideDeleteModal}>Cancel</Button>
                                <Button variant="danger" className="text-light" onClick={deleteSmartContract} >Delete</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={isDeploying} onHide={hideDeployModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Smart Contract ID #{smartContract._id}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                {
                                    !deployResult ? (
                                        <>
                                            <InputLabel className="text-dark text-center mt-5 mb-5" >Do you really want to deploy this smart contract for you ?</InputLabel>
                                            
                                            {
                                                typeToDeploy === "ERC20" && 
                                                <>
                                                    <Form.Group>
                                                        <InputLabel className="text-dark" >Coinbase index</InputLabel>
                                                        <Form.Control value={coinbaseIndex} onChange={(e) => setCoinbaseIndex(e.target.value)} />
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <InputLabel className="text-dark" >Coinbase password</InputLabel>
                                                        <Form.Control value={coinbasePassword} onChange={(e) => setCoinbasePassword(e.target.value)} />
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <InputLabel className="text-dark" >Initial supply</InputLabel>
                                                        <Form.Control value={initialSupply} onChange={(e) => setInitialSupply(e.target.value)} />
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <InputLabel className="text-dark" >Token name</InputLabel>
                                                        <Form.Control value={tokenName} onChange={(e) => setTokenName(e.target.value)} />
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <InputLabel className="text-dark" >Token symbol</InputLabel>
                                                        <Form.Control value={tokenSymbol} onChange={(e) => setTokenSymbol(e.target.value)} />
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <InputLabel className="text-dark" >Smart contract owner account address</InputLabel>
                                                        <Form.Control value={contractOwner} onChange={(e) => setContractOwner(e.target.value)} />
                                                    </Form.Group>
                                                
                                                    <Form.Group>
                                                        <InputLabel className="text-dark" >Smart contract owner private key</InputLabel>
                                                        <Form.Control value={contractOwnerPrivateKey} onChange={(e) => setContractOwnerPrivateKey(e.target.value)} />
                                                    </Form.Group>
                                                </>
                                            }
                                            {
                                                typeToDeploy === "vouchers" && 
                                                <>
                                                    <Form.Group>
                                                        <InputLabel className="text-dark" >Coinbase index</InputLabel>
                                                        <Form.Control value={coinbaseIndex} onChange={(e) => setCoinbaseIndex(e.target.value)} />
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <InputLabel className="text-dark" >Coinbase password</InputLabel>
                                                        <Form.Control value={coinbasePassword} onChange={(e) => setCoinbasePassword(e.target.value)} />
                                                    </Form.Group>
                                                </>
                                            }
                                            {
                                                typeToDeploy === "books" && 
                                                <>
                                                    <Form.Group>
                                                        <InputLabel className="text-dark" >Coinbase index</InputLabel>
                                                        <Form.Control value={coinbaseIndex} onChange={(e) => setCoinbaseIndex(e.target.value)} />
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <InputLabel className="text-dark" >Coinbase password</InputLabel>
                                                        <Form.Control value={coinbasePassword} onChange={(e) => setCoinbasePassword(e.target.value)} />
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <InputLabel className="text-dark" >Vouchers smart contract address </InputLabel>
                                                        <Form.Control value={addressVouchersContract} onChange={(e) => setAddressVoucherContract(e.target.value)} />
                                                    </Form.Group>
                                                </>
                                            }

                                        </>
                                    ) : (
                                        <>
                                            <div>
                                                <p>Smart contract deployed on blockchain : {deployResult.blockchainId}</p>
                                                <p>Application : {deployResult.applicationId}</p>
                                                <p>Type : {deployResult.type}</p>
                                                <p>Subtype : {deployResult.subtype}</p>
                                                <p><b>Smart Contract address : {deployResult.contractAddress}</b></p>
                                            </div>
                                        </>
                                    )
                                }

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className="float-left" onClick={hideDeployModal}>Cancel</Button>
                                {
                                    !deployResult &&
                                    <Button variant="success" className="text-light" onClick={deploy} >Deploy</Button>
                                }
                            </Modal.Footer>
                        </Modal>
                    </>
                }

            </>
        );
}

export default BlockchainInfos;
