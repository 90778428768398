import React, { useState, useEffect } from 'react';
import Request from '../../Config/Request.js';
import { useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import InputLabel from '@material-ui/core/InputLabel';
import Button from 'react-bootstrap/Button';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    formControl: {
      margin: theme.spacing(3),
    },
}));

function OVH(props) {

    let [errorCode,setErrorCode] = useState(null);
    let [errorMessage,setErrorMessage] = useState(null);
    let [user,setUser] = useState(JSON.parse(sessionStorage.getItem('user')));
    let [blockchain,setBlockchain] = useState(props.blockchainReceived ? props.blockchainReceived : { name : "" , ip : null , port : null , provider : null , isPublic : false , keywords : [] });
    const classes = useStyles();
    let history = useHistory();

    const handleCategorySelect = (cat,key) => {
        setBlockchain({ ...blockchain, keywords : blockchain.keywords.map((category,id) => {
                let categ = category;
                if ( id === cat ){
                    let newKeywords = categ.keywords;
                    for ( let i = 0 , l = newKeywords.length ; i < l ; ++i )
                        if ( i === key )
                            newKeywords[i].value = !newKeywords[i].value;
                    categ.keywords = newKeywords;
                }
                return categ;
            })
        });
    };

    let createContainer = async () => {
        try{
            let create = await Request.h64HttpRequest("POST","/blockchain/create/container",blockchain);
            if ( create.errorCode !== undefined && create.errorMessage !== undefined ){
                setErrorCode(create.errorCode);
                setErrorMessage(create.errorMessage);
                if ( create.errorCode === null && create.errorMessage === null ){
                    /* Execute code on success response */
                    history.push("/blockchains");
                }
            }
        }catch(err){
            console.error(err);
        }
    }

    let updateContainer = async () => {
        try{
            let create = await Request.h64HttpRequest("PUT","/blockchain/update/container",blockchain);
            if ( create.errorCode !== undefined && create.errorMessage !== undefined ){
                setErrorCode(create.errorCode);
                setErrorMessage(create.errorMessage);
                if ( create.errorCode === null && create.errorMessage === null ){
                    /* Execute code on success response */
                    history.push("/blockchains");
                }
            }
        }catch(err){
            console.error(err);
        }
    }

    useEffect( () => {
        try{
            const fetch = async () => {
                let theCategories = await Request.httpRequest("GET","/getKeywords",{});
                if ( theCategories )
                    if ( theCategories.errorCode !== undefined && theCategories.errorMessage !== undefined ){
                        setErrorCode(theCategories.errorCode);
                        setErrorMessage(theCategories.errorMessage);
                        if ( theCategories.errorCode === null && theCategories.errorMessage === null ){
                            theCategories = theCategories.keywords;
                            for ( let i = 0 , l = theCategories.length ; i < l ; ++i ){
                                let categoryKeywords = [];
                                for ( let j = 0 , k = theCategories[i].keywords.length ; j < k ; ++j )
                                    categoryKeywords.push({ label : theCategories[i].keywords[j] , value : false });
                                theCategories[i].keywords = categoryKeywords;
                            }

                            if ( props.blockchainReceived )
                                for ( let i = 0 , l = theCategories.length ; i < l ; ++i )
                                    for ( let j = 0 , k = theCategories[i].keywords.length ; j < k ; ++j )
                                        for ( let m = 0 , n = blockchain.keywords.length ; m < n ; ++m )
                                            if ( blockchain.keywords[m] === theCategories[i].keywords[j].label )
                                                theCategories[i].keywords[j].value = true;
                            setBlockchain({ ...blockchain, keywords : theCategories });
                        }
                    }
            }
            fetch();
        }catch(err){
            console.error(err);
        }
    },[]);

    return (
        <>
            <h5>Create a blockchain</h5>
            <p>Using OVH</p>

            {/* To remove this checker when OVH will be working again + perform debugs */}
            {
                props.blockchainReceived ? (
                    <>
                        <h5 className="mt-5" >
                            General informations
                        </h5>
                        <hr/>

                        {
                            props.blockchainReceived ? (
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Group className="mt-5">
                                            <InputLabel className="text-dark" >Name</InputLabel>
                                            <Form.Control value={blockchain.name} onChange={(e) => setBlockchain({...blockchain, name : e.target.value }) } />
                                        </Form.Group>
                    
                                        <Form.Group>
                                            <InputLabel className="text-dark" >Provider</InputLabel>
                                            <Form.Control value={blockchain.provider} onChange={(e) => setBlockchain({...blockchain, provider : e.target.value })} />
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="row d-flex">
                                            {
                                                blockchain.nodes.map((node,id) => 
                                                <Card style={{ "width" : "45%" }} className="ml-2 mr-2 mt-2 mb-2 shadow" >
                                                    <CardContent>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                            {node.VPSName}
                                                        </Typography>
                                                        <Typography variant="h5" component="h2">
                                                            {node.nodeName}
                                                        </Typography>
                                                        <Typography className={classes.pos} color="textSecondary">
                                                            {node.VPSlocalisation}
                                                        </Typography>
                                                        <Typography variant="body2" component="p">
                                                            <a target="_black" href={node.dashboard}>{node.dashboard}</a>
                                                        <br />
                                                            <span>{node.provider}</span>
                                                        </Typography>
                                                    </CardContent>
                                                    <CardActions>
                                                        <IconButton color="primary" aria-label="More informations about this node" component="span">
                                                            <HelpIcon />
                                                        </IconButton>
                                                    </CardActions>
                                                </Card>   
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <Form.Group className="mt-5">
                                        <InputLabel className="text-dark" >Name</InputLabel>
                                        <Form.Control value={blockchain.name} onChange={(e) => setBlockchain({...blockchain, name : e.target.value }) } />
                                    </Form.Group>

                                    <Form.Group>
                                        <InputLabel className="text-dark" >Provider</InputLabel>
                                        <Form.Control value={blockchain.provider} onChange={(e) => setBlockchain({...blockchain, provider : e.target.value })} />
                                    </Form.Group>
                                </>
                            ) 
                        }



                        <h5 className="mt-5" >
                            Blockchain visibility
                        </h5>
                        <hr/>

                        <div className={classes.root + " row"}>
                            {
                                blockchain.keywords && blockchain.keywords.map((category,categoryId) => 
                                    <div className="col-sm-4" key={categoryId}>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <FormLabel component="legend">{category.category}</FormLabel>
                                            <FormGroup>
                                                {
                                                    category.keywords && category.keywords.map((keyword,keywordId) => 
                                                        <FormControlLabel
                                                            key={keywordId}
                                                            control={<Checkbox checked={keyword.value} onChange={() => handleCategorySelect(categoryId,keywordId)} value={keyword.label} name={keyword.label} color="primary" />}
                                                            label={keyword.label}
                                                        />
                                                    )
                                                }
                                            </FormGroup>
                                            <FormHelperText>Choose wisely</FormHelperText>
                                        </FormControl>
                                    </div>
                                )
                            }   
                        </div>

                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" checked={blockchain.isPublic} onChange={(e) => setBlockchain({...blockchain, isPublic : !blockchain.isPublic }) } label="Free to use" />
                        </Form.Group>

                        <div className="row mt-5 text-center justify-content-center">
                            {
                                props.blockchainReceived ? (
                                    <Button variant="warning" size="large" className="text-light" onClick={updateContainer} >Update</Button>
                                ) : (
                                    <Button variant="success" size="large" className="text-light" onClick={createContainer} >Create</Button>
                                )
                            }
                        </div>       
                    </>
                ) : (
                    <></>
                )
            }

        </>
    );
}
 
export default OVH;