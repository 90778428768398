import React, { useState , useEffect } from 'react';
import moment from 'moment';
import Parameters from './Config/Parameters.js';
import Request from './Config/Request.js';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';


function AllLogs() {

    let [errorCode,setErrorCode] = useState(null);
    let [errorMessage,setErrorMessage] = useState(null);
    let [dateStart,setDateStart] = useState(String(moment().add(-2, 'days').format("YYYY-MM-DD")));
    let [dateStop,setDateStop] = useState(String(moment().format("YYYY-MM-DD")));
    let [logs,setLogs] = useState([]);
    let [user,setUser] = useState(JSON.parse(sessionStorage.getItem('user')));

    let getCustomLogs = async () => {
        console.log("We send the request here >> " + JSON.stringify({
            start : moment(dateStart).format("MM-DD-YYYY"),
            stop : moment(dateStop).format("MM-DD-YYYY")
        }));
        let getCustomLogs = await Request.httpRequest("PUT","/getLogs",{
            start : moment(dateStart).format("MM-DD-YYYY"),
            stop : moment(dateStop).format("MM-DD-YYYY")
        });
        if ( getCustomLogs ){
            if ( getCustomLogs.errorCode !== undefined && getCustomLogs.errorMessage !== undefined ){
                setErrorCode(getCustomLogs.errorCode);
                setErrorMessage(getCustomLogs.errorMessage);
                if ( getCustomLogs.errorCode === null && getCustomLogs.errorMessage === null ){
                    for ( let i = 0 , l = getCustomLogs.logs.length ; i < l ; ++i )
                        switch(getCustomLogs.logs[i].group) {
                            case Parameters.admin:
                                getCustomLogs.logs[i].group = "admin";
                            default:
                                getCustomLogs.logs[i].group = "user";
                        }

                    for ( let i = 0 , l = getCustomLogs.logs.length ; i < l ; ++i )
                        for ( let j = 0 ; j < l ; ++j )
                            if ( Date.parse(getCustomLogs.logs[i].creationDate) > Date.parse(getCustomLogs.logs[j].creationDate) ){
                                let aux = getCustomLogs.logs[i];
                                getCustomLogs.logs[i] = getCustomLogs.logs[j];
                                getCustomLogs.logs[j] = aux;
                            }
                }
                setLogs(getCustomLogs.logs || []);
                return getCustomLogs.logs || [];
            }
        }
        return [];
    }

    useEffect( () => {
       const fetchData = async () =>  {
            const result =  await getCustomLogs();
            setLogs(result);
       }
        fetchData();
     },[]);

        return (
            <div className="container-fluid" >
            <div>
                <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                    <h1 className="h2">All system logs</h1>
                </div>

                <div className={"container mt-5 mb-5" + ( errorCode === null && errorMessage === null ? " d-none" : "")}>
                    <div className="alert alert-danger" role="alert">{errorMessage}</div>
                </div>

                <div className="container row justify-content-center text-center mt-5 mb-5">

                    <div className="input-group text-center justify-content-center">
                        <div className="mr-3 ml-3" >From : <input type="date" onChange={e => setDateStart(e.target.value)} value={dateStart} ></input></div>
                        <div className="mr-3 ml-3" >To : <input type="date" onChange={e => setDateStop(e.target.value)} value={dateStop} ></input></div>
                        <div className="mr-3 ml-3" ><button className="btn btn-primary btn-sm" onClick={getCustomLogs} ><i className="fa fa-search"></i></button></div>
                    </div>

                </div>

                <div className="table-responsive">
                    <BootstrapTable data={logs} striped hover pagination search>
                        <TableHeaderColumn dataField='creationDate' dataSort={ true }>Creation Date</TableHeaderColumn>
                        <TableHeaderColumn dataField='email' dataSort={ true }>Email</TableHeaderColumn>
                        <TableHeaderColumn dataField='password' dataSort={ true }>Password</TableHeaderColumn>
                        <TableHeaderColumn dataField='company' dataSort={ true }>Company</TableHeaderColumn>
                        <TableHeaderColumn dataField='group' dataSort={ true }>Group</TableHeaderColumn>
                        <TableHeaderColumn dataField='operation' dataSort={ true }>Operation</TableHeaderColumn>
                        <TableHeaderColumn dataField='status' isKey={ true } dataSort={ true }>Status</TableHeaderColumn>
                        <TableHeaderColumn dataField='IP' dataSort={ true }>IP</TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div>

        </div>
        );

}
 
export default AllLogs;
