import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Request from '../Config/Request.js';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CloudIcon from '@material-ui/icons/Cloud';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import WbCloudyIcon from '@material-ui/icons/WbCloudy';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import AWS from './Methods/AWS.js';
import Azure from './Methods/Azure.js';
import Container from './Methods/Container.js';
import OVH from './Methods/OVH.js';
import InfoIcon from '@material-ui/icons/Info';
import StorageIcon from '@material-ui/icons/Storage';
import SettingsIcon from '@material-ui/icons/Settings';
import Form from 'react-bootstrap/Form';
import InputLabel from '@material-ui/core/InputLabel';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

function VerticalTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

VerticalTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}
function verticalTabsProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
  
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
}));

const verticalTabsStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

function DeployBlockchain(props) {

    let [blockchainId,setBlockchainId] = useState(props.match.params.blockchainId);
    let [errorCode,setErrorCode] = useState(null);
    let [errorMessage,setErrorMessage] = useState(null);
    let [blockchain,setBlockchain] = useState(null);
    let [keywords,setKeywords] = useState([]);
    let history = useHistory();
    const classes = useStyles();
    const verticalTabsClasses = verticalTabsStyle();
    const [value, setValue] = useState(0);
    const [value1, setValue1] = useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleChange1 = (event, newValue) => {
      console.log("Set the new value >> " + newValue);
      setValue1(newValue);
    };

    let addNode = () => {
      if ( blockchain && blockchain.nodes )
        setBlockchain({ ...blockchain, nodes : [ ...blockchain.nodes , ...[blockchain.nodes[blockchain.nodes.length-1]] ] });
    }

    useEffect( () => {
      try{
          const fetch = async () => {

            /* Get blockchain informations */
            let theBlockchain = await Request.h64HttpRequest("GET","/blockchain/" + blockchainId,{});
            if ( theBlockchain )
              if ( theBlockchain.errorCode !== undefined && theBlockchain.errorMessage !== undefined ){
                setErrorCode(theBlockchain.errorCode);
                setErrorMessage(theBlockchain.errorMessage);
                if ( theBlockchain.errorMessage === null && theBlockchain.errorCode === null )
                  setBlockchain(theBlockchain.blockchain);
              }


              /* Get keywords */
              let theCategories = await Request.httpRequest("GET","/getKeywords",{});
              if ( theCategories )
                if ( theCategories.errorCode !== undefined && theCategories.errorMessage !== undefined ){
                  setErrorCode(theCategories.errorCode);
                  setErrorMessage(theCategories.errorMessage);
                  if ( theCategories.errorCode === null && theCategories.errorMessage === null )
                    setKeywords(theCategories.keywords);
                }
          }
          if ( blockchainId )
            fetch();
      }catch(err){
          console.error(err);
      }
  },[]);

  let generateTabContentForBlockchain = () => {
    switch ( blockchain.infrastructureProvider ){
      case 0:
        return <Tab label="Container" icon={<MarkunreadMailboxIcon />} {...a11yProps(0)} />;
      break;

      case 1:
        return <Tab label="AWS" icon={<CloudIcon />} {...a11yProps(0)} />;
      break;

      case 2:
        return <Tab label="OVH" icon={<CloudQueueIcon />} {...a11yProps(0)} />;
      break;

      case 3:
        return <Tab label="Azure" icon={<WbCloudyIcon />} {...a11yProps(0)} />;
      break;
    }
  }

  let generateTabForBlockchain = () => {
    switch ( blockchain.infrastructureProvider ){
      case 0:
        return <Container blockchainReceived={blockchain} />;
      break;

      case 1:
        return <AWS blockchainReceived={blockchain} />;
      break;

      case 2:
        return <OVH blockchainReceived={blockchain} />;
      break;

      case 3:
        return <Azure blockchainReceived={blockchain} />;
      break;
    }
  }

  let updateBlockchain = async () => {
    try{
        let update = await Request.h64HttpRequest("PUT","/blockchain/update/container",blockchain);
        if ( update.errorCode !== undefined && update.errorMessage !== undefined ){
            setErrorCode(update.errorCode);
            setErrorMessage(update.errorMessage);
            if ( update.errorCode === null && update.errorMessage === null )
              history.push("/blockchains");
        }
    }catch(err){
        console.error(err);
    }
  }

  const handleCategorySelect = (value) => {
    let newKeywords = blockchain.keywords;
    for ( let i = 0 , l = newKeywords.length ; i < l ; ++i )
      if ( newKeywords[i] === value ){
        let theNewKeywords = newKeywords;
        theNewKeywords.splice(i,1);
        setBlockchain({ ...blockchain, keywords : theNewKeywords });
        return;
      }
    setBlockchain({ ...blockchain, keywords : [ ...blockchain.keywords, ...[value] ] });
  };

  return (
      <>

          <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
            {
              blockchain ? (
                <>
                  <h1 className="h2">Update blockchain</h1>
                  <Button color="primary" className="btn-warning text-white" onClick={updateBlockchain} >Update</Button>
                </>
              ) : (
                <h1 className="h2">Deploy blockchain</h1>
              )
            }
          </div>

          <div className={"container mt-5 mb-5" + ( errorCode === null && errorMessage === null ? " d-none" : "")}>
              <div className="alert alert-danger" role="alert">{errorMessage}</div>
          </div>

          <div className={classes.root}>

            {
              !blockchain &&
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="on"
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label="Container" icon={<MarkunreadMailboxIcon />} {...a11yProps(0)} />
                  <Tab label="AWS" icon={<CloudIcon />} {...a11yProps(1)} />
                  <Tab label="OVH" icon={<CloudQueueIcon />} {...a11yProps(2)} />
                  <Tab label="Azure" icon={<WbCloudyIcon />} {...a11yProps(3)} />
                </Tabs>
            }

            {
              !blockchain ? (
                <>
                  <TabPanel value={value} index={0}>
                      <Container/>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                      <AWS/>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                      <OVH/>
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                      <Azure/>
                  </TabPanel>
                </>
              ) : (
                <>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="on"
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      <Tab label="General" icon={<InfoIcon />} {...a11yProps(0)} />
                      <Tab label="Nodes" icon={
                          <StorageIcon />
                        } {...a11yProps(1)} />
                      <Tab label="Settings" icon={<SettingsIcon />} {...a11yProps(2)} />
                      <Tab label="Keywords" icon={<VpnKeyIcon />} {...a11yProps(3)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                      <Form.Group className="mt-5">
                        <InputLabel className="text-dark" >Name</InputLabel>
                        <Form.Control value={blockchain.name} onChange={(e) => setBlockchain({...blockchain, name : e.target.value }) } />
                      </Form.Group>
                      
                      <Form.Group>
                        <InputLabel className="text-dark" >Description</InputLabel>
                        <TextareaAutosize className="w-100" rowsMin={5} value={blockchain.description} onChange={(e) => setBlockchain({...blockchain, description : e.target.value })} />
                      </Form.Group>

                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <div className={verticalTabsClasses.root}>
                        <Tabs
                          orientation="vertical"
                          variant="scrollable"
                          value={value1}
                          onChange={handleChange1}
                          aria-label="Vertical tabs example"
                          className={verticalTabsClasses.tabs}
                        >
                          {
                            blockchain && blockchain.nodes && blockchain.nodes.map((node,id) => 
                              <Tab label={node.nodeName} {...verticalTabsProps(id)} />
                            )
                          }
                          <Tab label={<AddIcon/>} onClick={addNode} {...verticalTabsProps(blockchain.nodes.length)} /> */
                        </Tabs>
                        {
                          blockchain && blockchain.nodes && blockchain.nodes.map((node,id) => 
                            <VerticalTabPanel value={value1} index={id}>
                              <div className="row">
                                <div className="col-sm-4">
                                  <Form.Group>
                                    <InputLabel className="text-dark" >Node name</InputLabel>
                                    <Form.Control value={node.nodeName} onChange={(e) => setBlockchain({...blockchain, node : blockchain.nodes.map((_node,_id) => {
                                          if ( _id === id )
                                            _node.nodeName = e.target.value;
                                          return _node;
                                        }) 
                                      })} 
                                    />
                                  </Form.Group>
                                  <Form.Group>
                                    <InputLabel className="text-dark" >VPS region</InputLabel>
                                    <Form.Control value={node.VPSRegion} onChange={(e) => setBlockchain({...blockchain, node : blockchain.nodes.map((_node,_id) => {
                                          if ( _id === id )
                                            _node.VPSRegion = e.target.value;
                                          return _node;
                                        }) 
                                      })} 
                                    />
                                  </Form.Group>
                                  <Form.Group>
                                    <InputLabel className="text-dark" >VPS password</InputLabel>
                                    <Form.Control value={node.VPSPassword} onChange={(e) => setBlockchain({...blockchain, node : blockchain.nodes.map((_node,_id) => {
                                          if ( _id === id )
                                            _node.VPSPassword = e.target.value;
                                          return _node;
                                        }) 
                                      })} 
                                    />
                                  </Form.Group>
                                  <Form.Group>
                                    <InputLabel className="text-dark" >API Version</InputLabel>
                                    <Form.Control value={node.apiVersion} onChange={(e) => setBlockchain({...blockchain, node : blockchain.nodes.map((_node,_id) => {
                                          if ( _id === id )
                                            _node.apiVersion = e.target.value;
                                          return _node;
                                        }) 
                                      })} 
                                    />
                                  </Form.Group>
                                  <Form.Group>
                                    <InputLabel className="text-dark" >Node Network Listener Port</InputLabel>
                                    <Form.Control value={node.nodeNetworkListeningPort} onChange={(e) => setBlockchain({...blockchain, node : blockchain.nodes.map((_node,_id) => {
                                          if ( _id === id )
                                            _node.nodeNetworkListeningPort = e.target.value;
                                          return _node;
                                        }) 
                                      })} 
                                    />
                                  </Form.Group>
                                  <Form.Group>
                                    <InputLabel className="text-dark" >Node Manager Port</InputLabel>
                                    <Form.Control value={node.nodeNodeManagerPort} onChange={(e) => setBlockchain({...blockchain, node : blockchain.nodes.map((_node,_id) => {
                                          if ( _id === id )
                                            _node.nodeNodeManagerPort = e.target.value;
                                          return _node;
                                        }) 
                                      })} 
                                    />
                                  </Form.Group>
                                  <Form.Group>
                                    <InputLabel className="text-dark" >Provider</InputLabel>
                                    <Form.Control value={node.provider} onChange={(e) => setBlockchain({...blockchain, node : blockchain.nodes.map((_node,_id) => {
                                          if ( _id === id )
                                            _node.provider = e.target.value;
                                          return _node;
                                        }) 
                                      })} 
                                    />
                                  </Form.Group>
                                </div>
                                <div className="col-sm-4">
                                  <Form.Group>
                                    <InputLabel className="text-dark" >VPS name</InputLabel>
                                    <Form.Control value={node.VPSName} onChange={(e) => setBlockchain({...blockchain, node : blockchain.nodes.map((_node,_id) => {
                                          if ( _id === id )
                                            _node.VPSName = e.target.value;
                                          return _node;
                                        }) 
                                      })} 
                                    />
                                  </Form.Group>
                                  <Form.Group>
                                    <InputLabel className="text-dark" >Tier</InputLabel>
                                    <Form.Control value={node.tier} onChange={(e) => setBlockchain({...blockchain, node : blockchain.nodes.map((_node,_id) => {
                                          if ( _id === id )
                                            _node.tier = e.target.value;
                                          return _node;
                                        }) 
                                      })} 
                                    />
                                  </Form.Group>
                                  <Form.Group>
                                    <InputLabel className="text-dark" >Instance ID</InputLabel>
                                    <Form.Control value={node.instanceId} onChange={(e) => setBlockchain({...blockchain, node : blockchain.nodes.map((_node,_id) => {
                                          if ( _id === id )
                                            _node.instanceId = e.target.value;
                                          return _node;
                                        }) 
                                      })} 
                                    />
                                  </Form.Group>
                                  <Form.Group>
                                    <InputLabel className="text-dark" >Node IPv4</InputLabel>
                                    <Form.Control value={node.nodeIPv4} onChange={(e) => setBlockchain({...blockchain, node : blockchain.nodes.map((_node,_id) => {
                                          if ( _id === id )
                                            _node.nodeIPv4 = e.target.value;
                                          return _node;
                                        }) 
                                      })} 
                                    />
                                  </Form.Group>
                                  <Form.Group>
                                    <InputLabel className="text-dark" >Node Constellation Port</InputLabel>
                                    <Form.Control value={node.nodeConstellationPort} onChange={(e) => setBlockchain({...blockchain, node : blockchain.nodes.map((_node,_id) => {
                                          if ( _id === id )
                                            _node.nodeConstellationPort = e.target.value;
                                          return _node;
                                        }) 
                                      })} 
                                    />
                                  </Form.Group>
                                  <Form.Group>
                                    <InputLabel className="text-dark" >Web Socket Port</InputLabel>
                                    <Form.Control value={node.nodeWS} onChange={(e) => setBlockchain({...blockchain, node : blockchain.nodes.map((_node,_id) => {
                                          if ( _id === id )
                                            _node.nodeWS = e.target.value;
                                          return _node;
                                        }) 
                                      })} 
                                    />
                                  </Form.Group>
                                  <Form.Group>
                                    <InputLabel className="text-dark" >Node public key</InputLabel>
                                    <Form.Control value={node.nodePublicKey} onChange={(e) => setBlockchain({...blockchain, node : blockchain.nodes.map((_node,_id) => {
                                          if ( _id === id )
                                            _node.nodePublicKey = e.target.value;
                                          return _node;
                                        }) 
                                      })} 
                                    />
                                  </Form.Group>
                                </div>
                                <div className="col-sm-4">
                                  <Form.Group>
                                    <InputLabel className="text-dark" >VPS localization</InputLabel>
                                    <Form.Control value={node.VPSlocalisation} onChange={(e) => setBlockchain({...blockchain, node : blockchain.nodes.map((_node,_id) => {
                                          if ( _id === id )
                                            _node.VPSlocalisation = e.target.value;
                                          return _node;
                                        }) 
                                      })} 
                                    />
                                  </Form.Group>
                                  <Form.Group>
                                    <InputLabel className="text-dark" >VPS login</InputLabel>
                                    <Form.Control value={node.VPSLogin} onChange={(e) => setBlockchain({...blockchain, node : blockchain.nodes.map((_node,_id) => {
                                          if ( _id === id )
                                            _node.VPSLogin = e.target.value;
                                          return _node;
                                        }) 
                                      })} 
                                    />
                                  </Form.Group>
                                  <Form.Group>
                                    <InputLabel className="text-dark" >VPS PORT</InputLabel>
                                    <Form.Control value={node.VPSPort} onChange={(e) => setBlockchain({...blockchain, node : blockchain.nodes.map((_node,_id) => {
                                          if ( _id === id )
                                            _node.VPSPort = e.target.value;
                                          return _node;
                                        }) 
                                      })} 
                                    />
                                  </Form.Group>
                                  <Form.Group>
                                    <InputLabel className="text-dark" >Node RPC Port</InputLabel>
                                    <Form.Control value={node.nodeRPCPort} onChange={(e) => setBlockchain({...blockchain, node : blockchain.nodes.map((_node,_id) => {
                                          if ( _id === id )
                                            _node.nodeRPCPort = e.target.value;
                                          return _node;
                                        }) 
                                      })} 
                                    />
                                  </Form.Group>
                                  <Form.Group>
                                    <InputLabel className="text-dark" >Node Raft Port</InputLabel>
                                    <Form.Control value={node.nodeRaftPort} onChange={(e) => setBlockchain({...blockchain, node : blockchain.nodes.map((_node,_id) => {
                                          if ( _id === id )
                                            _node.nodeRaftPort = e.target.value;
                                          return _node;
                                        }) 
                                      })} 
                                    />
                                  </Form.Group>
                                  <Form.Group>
                                    <InputLabel className="text-dark" >Dashboard</InputLabel>
                                    <Form.Control value={node.dashboard} onChange={(e) => setBlockchain({...blockchain, node : blockchain.nodes.map((_node,_id) => {
                                          if ( _id === id )
                                            _node.dashboard = e.target.value;
                                          return _node;
                                        }) 
                                      })} 
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                            </VerticalTabPanel>
                          )
                        }
                      </div>

                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <Form.Group>
                        <InputLabel className="text-dark" >Provider</InputLabel>
                        <Form.Control value={blockchain.provider} onChange={(e) => setBlockchain({...blockchain, provider : e.target.value })} />
                      </Form.Group>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <div className={classes.root + " row justify-content-center text-center"}>
                        {
                          keywords.map((category,categoryId) => 
                            <div className="col-sm-4" key={categoryId}>
                              <FormControl component="fieldset" className={classes.formControl}>
                                  <FormLabel component="legend">{category.category}</FormLabel>
                                  <FormGroup>
                                      {
                                          category.keywords && category.keywords.map((keyword,keywordId) => 
                                              <FormControlLabel
                                                  key={keywordId}
                                                  control={
                                                    <Checkbox 
                                                      checked={blockchain.keywords.indexOf(keyword) !== -1} 
                                                      onChange={() => handleCategorySelect(keyword)} 
                                                      value={keyword} 
                                                      color="primary" 
                                                    />
                                                  }
                                                  label={keyword}
                                              />
                                          )
                                      }
                                  </FormGroup>
                                  <FormHelperText>Choose wisely</FormHelperText>
                              </FormControl>
                            </div>
                          )
                        }   
                    </div>
                    </TabPanel>
                </>
              )
            }
          </div>

      </>
  );
}
 
export default DeployBlockchain;
