import React , { useState , useEffect } from 'react';
import Request from './Config/Request.js';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Button from 'react-bootstrap/Button';
import { Link , useHistory } from 'react-router-dom';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import getKeywords from './Common/getKeywords.js';
import getCategories from './Common/getCategories.js';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    formControl: {
      margin: theme.spacing(3),
    },
}));


function BlockchainInfos(props) {
    const scDescriptionMinLength = 5;
    const scTitleMinLength = 3;
    const smartContractId = props.match.params.smartContractId;
    let [errorCode,setErrorCode] = useState(null);
    let [errorMessage,setErrorMessage] = useState(null);
    let [smartContract,setSmartContract] = useState("to get...");
    let [smartContracts,setSmartContracts] = useState("to get...");
    let [deploySC,setDeploySC] = useState(null);
    let [isEditing,setEditing] = useState(false);
    let [user,setUser] = useState(JSON.parse(sessionStorage.getItem('user')));
    let [solFiles,setSolFiles] = useState(null);
    let [constructor,setConstructor] = useState(null);
    let [categories,setCategories] = useState(null);
    const classes = useStyles();
    let [isChoosingSmartContract,setChoosingSmartContract] = useState(null);
    let [isCore,setIsCore] = useState(true);
    let [forPlugins,setForPlugins] = useState([]);
    let [keywords,setKeywords] = useState([]);
    let history = useHistory();

    let setCore = (e) => {
        switch ( e.target.value ){
            case "core":
                setIsCore(true);
            break;

            case "plugin":
                setIsCore(false);
                setForPlugins([]);
            break;
        }
        setForPlugins(e.target.value);
    }

    let handlePlugins = (sc) => {
        if ( forPlugins.indexOf(sc._id) === -1 )
            setForPlugins([ ...forPlugins , ...[sc._id] ]);
        else
            setForPlugins(forPlugins.filter((core) => core !== sc._id));
    }

    let getSmartContracts = async () => {
        let smartContracts = await Request.h64HttpRequest("GET","/getAllLibrarySmartContracts",{});
        return smartContracts.smartContracts || [];
    }

    let generateSmartContractStatusUI = () => {
        return <>
            <Form.Group>
                <RadioGroup aria-label="Smart Contract Status" value={isCore} onChange={setCore}>
                    <FormControlLabel value="core" checked={isCore} control={<Radio />} label="Core" />
                    <FormControlLabel value="plugin" checked={!isCore} control={<Radio />} label="Plugin" />
                </RadioGroup>
            </Form.Group>
            {
                isCore && 
                <div className="container">
                    <div className="row">
                        <h5>Please select the plugins for this smart contract</h5>
                    </div>
                    <div className="list-group">
                        {
                            generateRoots().map((root,id) => 
                                <a key={id} className={"list-group-item list-group-item-action " + ( forPlugins && forPlugins.indexOf(root._id) !== -1 ? "text-white active" : "text-dark" )} onClick={() => handlePlugins(root)} >
                                    <p><span className="font-weight-bold">{root.title}</span> Smart Contract : {root.description}</p>
                                </a>
                            )
                        }
                    </div>
                </div>
            }
        </>;
    }

    const handleCategorySelect = (cat,key) => {
        setKeywords(keywords.map((category,id) => {
            let categ = category;
            if ( id === cat ){
                let newKeywords = categ.keywords;
                for ( let i = 0 , l = newKeywords.length ; i < l ; ++i )
                    if ( i === key )
                        newKeywords[i].value = !newKeywords[i].value;
                categ.keywords = newKeywords;
            }
            return categ;
        }));
    };

    let getSmartContract = async () => {
        try{
        if ( smartContractId === "deployTemplate" ){
            setDeploySC({ 
                abi : "" , 
                bytecode : "" , 
                title : "" , 
                description : "" , 
                constructorParameters : [] , 
                errors : [] , 
                isCore : true ,
                forPlugins : [] ,
                type : [] ,
                subtype : [] ,
                productOverview : "",
                furtherReadingLinks : [{ url : "" , text : "" }],
                highlights : "",
                implementationTime : "",
                requiredExperience : ""
            });
            return null;
        }
        let theSmartContract = await Request.h64HttpRequest("GET","/getSmartContract/" + smartContractId,{});
        if ( typeof theSmartContract.smartContract.furtherReadingLinks === "undefined" || theSmartContract.smartContract.furtherReadingLinks.length === 0 )
            theSmartContract.smartContract.furtherReadingLinks = [{ url : "" , text : "" }];
        return theSmartContract ? theSmartContract.smartContract : null;
        }catch(err){
            console.error(err);
            return null;
        }
    }

    const options = {
        clickToEdit: true,
        clickToSelect: true,
    };

    let getBlockchainCreationDate = (cell, row) => {
        return <>{moment(row.creationDate).format("L HH:mm:ss")}</>
    }

    let chooseSmartContract = (contract) => {
        if ( deploySC ){
            let toDeploySCConstructors = deploySC.constructorParameters;
            toDeploySCConstructors[isChoosingSmartContract.scId][isChoosingSmartContract.scName][isChoosingSmartContract.pId].chosenContract = {
                _id : contract._id,
                isPublic : contract.isPublic,
                _userAccountId : contract._userAccountId,
                type : contract.type,
                subtype : contract.subtype,
                title : contract.title,
                description : contract.description
            };
            setDeploySC({ ...deploySC, constructorParameters : toDeploySCConstructors });
        }
        if ( smartContract ){
            let smartContractsToModify = smartContract.smartContracts;
            smartContractsToModify[isChoosingSmartContract.scId].constructorParameters[isChoosingSmartContract.pId].chosenContract = {
                _id : contract._id,
                isPublic : contract.isPublic,
                _userAccountId : contract._userAccountId,
                type : contract.type,
                subtype : contract.subtype,
                title : contract.title,
                description : contract.description
            };
            setSmartContract({ ...smartContract, smartContracts : smartContractsToModify });
        }
        hideChoosingSmartContract();
    }

    let operations = (cell, row) => {
        return <>
            <Button variant="success" onClick={() => chooseSmartContract(row)} ><i className="fas fa-plus"></i></Button>
        </>;
    }

    useEffect( () => {
        try{
            const fetch = async () => {
                let typesSubtypes = await getCategories();
                console.log("Categories are >> " + JSON.stringify(typesSubtypes));
                setCategories(typesSubtypes);
                let theSmartContract = await getSmartContract();
                let contracts = await getSmartContracts();
                setSmartContracts(contracts);
                let theCategories = await Request.httpRequest("GET","/getSmartContractKeywords",{});
                if ( theCategories )
                    if ( theCategories.errorCode !== undefined && theCategories.errorMessage !== undefined ){
                        setErrorCode(theCategories.errorCode);
                        setErrorMessage(theCategories.errorMessage);
                        if ( theCategories.errorCode === null && theCategories.errorMessage === null ){
                            theCategories = theCategories.keywords;
                            for ( let i = 0 , l = theCategories.length ; i < l ; ++i ){
                                let categoryKeywords = [];
                                for ( let j = 0 , k = theCategories[i].keywords.length ; j < k ; ++j )
                                    categoryKeywords.push({ label : theCategories[i].keywords[j] , value : false });
                                theCategories[i].keywords = categoryKeywords;
                            }

                            if ( theSmartContract )
                                if ( theSmartContract.keywords ){
                                    for ( let i = 0 , l = theSmartContract.keywords.length ; i < l ; ++i )
                                        for ( let j = 0 , k = theCategories.length ; j < k ; ++j )
                                            for ( let m = 0 , n = theCategories[j].keywords.length ; m < n ; ++m )
                                                if ( theCategories[j].keywords[m].label === theSmartContract.keywords[i] )
                                                    theCategories[j].keywords[m].value = true;
                                    theSmartContract.keywords = theCategories;
                                }else{
                                    theSmartContract.keywords = [];
                                }
                            setKeywords(theCategories);
                        }
                    }
                setSmartContract(theSmartContract);
                if ( theSmartContract ){
                    setIsCore(theSmartContract.isCore || false);
                    setForPlugins(theSmartContract.forPlugins || []);
                }
            }
            fetch();
        }catch(err){
            console.error(err);
        }
    },[]);

    let edit = (row) => {
        setSmartContract(row);
        setEditing(true);
    }

    let updateSmartContract = async () => {
        try{
            let update = await Request.h64HttpRequest("PATCH","/updateSmartContract",{ 
                contractId : smartContract._id , 
                type : smartContract.type , 
                subtype : smartContract.subtype , 
                isCore : isCore ,
                forPlugins : forPlugins ,
                title : smartContract.title , 
                smartContracts : smartContract.smartContracts ,
                description : smartContract.description , 
                isPublic : smartContract.isPublic ,
                productOverview : smartContract.productOverview ,
                furtherReadingLinks : smartContract.furtherReadingLinks ,
                highlights : smartContract.highlights ,
                implementationTime : smartContract.implementationTime ,
                requiredExperience : smartContract.requiredExperience ,
                keywords : smartContract.keywords
            });
            console.log("Is ok ? " + JSON.stringify(update));
            setErrorCode(update.errorCode);
            setErrorMessage(update.errorMessage);
            if ( update.errorCode === null && update.errorMessage === null )
                history.push("/smartContracts");
        }catch(err){
            console.error(err);
        }
    }

    let hideChoosingSmartContract = () => {
        setChoosingSmartContract(null)
    }

    let showConstructor = (sc) => {
        setConstructor(sc.constructorParameters);
    }

    let getCategorySubcategoriesBySmartContractCategoryName = () => {
        if ( smartContract && smartContract.type )
            for ( let i = 0 , l = categories.length ; i < l ; ++i )
                if ( categories[i].name === smartContract.type )
                    return categories[i].subcategories;
        return [];
    }

    let hideEditModal = () => {
        setEditing(false);
        setSmartContract(null);
    }

    let deploySmartContract = async () => {
        try{
            let deploySCToSend = deploySC;
            if ( deploySCToSend.description.length < scDescriptionMinLength ){
                setErrorCode(404);
                setErrorMessage("Description length too short , minimum " + scDescriptionMinLength + " characters");
                return;
            }
            if ( deploySCToSend.title.length < scTitleMinLength ){
                setErrorCode(404);
                setErrorMessage("Description length too short , minimum " + scTitleMinLength + " characters");
                return;
            }
            for ( let key in deploySCToSend.constructorParameters )
                for ( let i in deploySCToSend.constructorParameters[key] )
                    for ( let j = 0 , k = deploySCToSend.constructorParameters[key][i].length ; j < k ; ++j ){
                        if ( deploySCToSend.constructorParameters[key][i][j].chosenContract ){
                            deploySCToSend.constructorParameters[key][i][j].chosenContract = deploySCToSend.constructorParameters[key][i][j].chosenContract._id;
                            console.log(JSON.stringify(deploySCToSend.constructorParameters[key][i][j]));
                            if ( deploySCToSend.constructorParameters[key][i][j].codeName.length === 0 ){
                                setErrorCode(404);
                                setErrorMessage("Please complete all deployment parameters values");
                                return;
                            }
                        }
                    }

            deploySCToSend.data = deploySCToSend.bytecode;
            deploySCToSend.password = user.passwordRaw;
            deploySCToSend.email = user.email;
            deploySCToSend.accountID = user.h64AccountID;
            deploySCToSend.categories = keywords;
            deploySCToSend.isCore = isCore;
            if ( forPlugins.length > 0 )
                deploySCToSend.forPlugins = forPlugins;
            let fd = new FormData();
            console.log("The sc to deploy is >> " + JSON.stringify(deploySCToSend));
            fd.append('scToDeploy',JSON.stringify(deploySCToSend));
            for ( let i = 0 , l = solFiles.length ; i < l ; ++i )
                fd.append(solFiles[i].name, solFiles[i], solFiles[i].name);
            let deploy = await Request.h64HttpRequestFile("/v1/deployContracts/publish",fd);
            console.log("Deploy result >> " + JSON.stringify(deploy));
            if ( deploy )
                if ( deploy.errorCode !== undefined && deploy.errorMessage !== undefined ){
                    setErrorCode(deploy.errorCode);
                    setErrorMessage(deploy.errorMessage);
                    if ( deploy.errorCode === null && deploy.errorMessage === null )
                        history.push("/smartContracts");
                }
        }catch(err){
            console.error(err);
            setErrorCode(404);
            setErrorMessage("Internal error");
            return;
        }
    }

    let handleJsonRowObj = (row) => {
        for ( let i in row )
            return { scName : i , params : row[i] };
    }
    
    let handleChange = async (file) => {
        setSolFiles(file.target.files);
        let fd = new FormData();
        for ( let i = 0 , l = file.target.files.length ; i < l ; ++i )
            fd.append(file.target.files[i].name, file.target.files[i], file.target.files[i].name);
        let ABI = await Request.h64HttpRequestFile("/convertToABI",fd);
        let paramsToDeploy = [];
        let errors = [];
        if ( ABI.errorCode === null && ABI.errorMessage === null ){
            for ( let o = 0 , p = ABI.ABI.length ; o < p ; ++o )
                if ( typeof ABI.ABI[o].error === "undefined" )
                    for ( let i in ABI.ABI[o] ){
                            let params = {};
                            params[i] = [];
                            for ( let j = 0 , k = ABI.ABI[o][i].abi.length ; j < k ; ++j )
                                if ( ABI.ABI[o][i].abi[j].type === "constructor" )
                                    for ( let m = 0 , n = ABI.ABI[o][i].abi[j].inputs.length ; m < n ; ++m )
                                        params[i].push({ codeName : ABI.ABI[o][i].abi[j].inputs[m].name , displayName : "" , value : "" , type : ABI.ABI[o][i].abi[j].inputs[m].type });
                            paramsToDeploy.push(params);
                    }
                else
                    errors.push(ABI.ABI[o]);
            setDeploySC({...deploySC, errors : errors , constructorParameters : paramsToDeploy });
        }
    }

    let setconstructorParameters = (obj,position,value) => {
        let currentconstructorParameters = deploySC.constructorParameters;
        for ( let i in currentconstructorParameters[obj.sc] )
            currentconstructorParameters[obj.sc][i][obj.id][position] = value;
        setDeploySC({ ...deploySC, constructorParameters : currentconstructorParameters });
    }

    let addLink = () => {
        if ( deploySC )
            setDeploySC({ ...deploySC, furtherReadingLinks : [ ...deploySC.furtherReadingLinks, { url : "" , text : "" } ]});
        if ( smartContract )
            setSmartContract({ ...smartContract, furtherReadingLinks : [ ...smartContract.furtherReadingLinks, { url : "" , text : "" } ]});
    }

    let removeLink = (id) => {
        let newLinks = [];
        if ( deploySC ){
            for ( let i = 0 , l = deploySC.furtherReadingLinks.length ; i < l ; ++i )
                if ( i !== id )
                    newLinks.push(deploySC.furtherReadingLinks[i]);
            setDeploySC({ ...deploySC, furtherReadingLinks : newLinks });
        }
        if ( smartContract ){
            for ( let i = 0 , l = smartContract.furtherReadingLinks.length ; i < l ; ++i )
                if ( i !== id )
                    newLinks.push(smartContract.furtherReadingLinks[i]);
            setSmartContract({ ...smartContract, furtherReadingLinks : newLinks });
        }
    }

    let setLinkProperty = (index,prop,value) => {
        if ( deploySC ){
            let theLinks = deploySC.furtherReadingLinks;
            theLinks[index][prop] = value;
            setDeploySC({ ...deploySC, furtherReadingLinks : theLinks });
        }
        if ( smartContract){
            let theLinks = smartContract.furtherReadingLinks;
            theLinks[index][prop] = value;
            setSmartContract({ ...smartContract, furtherReadingLinks : theLinks });
        }
    }

    let hideConstructor = () => {
        setConstructor(null);
    }

    let checkSubtype = (subtype) => {
        if ( deploySC ){
            let subtypes = deploySC.subtype;
            for ( let i = 0 , l = subtypes.length ; i < l ; ++i )
                if ( subtypes[i] === subtype )
                    return true;
            return false;
        }
        if ( smartContract){
            let subtypes = smartContract.subtype;
            for ( let i = 0 , l = subtypes.length ; i < l ; ++i )
                if ( subtypes[i] === subtype )
                    return true;
            return false;
        }
    }

    let setSubtype = (type,subtype) => {
        let subtypes = [] , types = [];
        if ( deploySC ){
            subtypes = deploySC.subtype;
            types = deploySC.type;
        }
        if ( smartContract ){
            subtypes = smartContract.subtype;
            types = smartContract.type;
        }
        let typeExists = false , subtypeExists = false;
        for ( let i = 0 , l = types.length ; i < l ; ++i )
            if ( types[i] === type ){
                typeExists = true;
                types.splice(i,1);
            }
        if ( !typeExists )
            types.push(type);
        for ( let i = 0 , l = subtypes.length ; i < l ; ++i )
            if ( subtypes[i] === subtype ){
                subtypeExists = true;
                subtypes.splice(i,1);
            }
        if ( !subtypeExists )
            subtypes.push(subtype);
        if ( deploySC ){
            setDeploySC({ ...deploySC, type : types , subtype : subtypes });
        }
        if ( smartContract ){
            setSmartContract({ ...smartContract, type : types , subtype : subtypes });
        }
    }

    let setChooseSmartContract = (param,scId,scName,pId) => {
        if ( smartContract && !deploySC )
            setChoosingSmartContract(param.dataType === "address" ? { ...param , scId : scId , scName : scName , pId : pId } : null);
        if ( !smartContract && deploySC )
            setChoosingSmartContract(param.type === "address" ? { ...param , scId : scId , scName : scName , pId : pId } : null);
    }

    let generateRoots = () => {
        let roots = [];
        if ( typeof smartContracts === "object" )
            for ( let i = 0 , l = smartContracts.length ; i < l ; ++i )
                if ( !smartContracts[i].isCore )
                    roots.push(smartContracts[i]);
        return roots;
    }

        return (
            <>

                <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                    {
                        smartContract === null && 
                        <h2>Create Smart Contract Template</h2>
                    }
                    {
                        typeof smartContract === "object" && smartContract !== null && 
                        <>
                            <h2>Update Smart Contract Template</h2>
                        </>
                    }
                </div>

                <div className={"container mt-5 mb-5" + ( errorCode === null && errorMessage === null ? " d-none" : "")}>
                    <div className="alert alert-danger" role="alert">{errorMessage}</div>
                </div>

                {
                    smartContract === null && deploySC !== null && 
                    <div>
                        {
                            deploySC.errors.length > 0 && 
                            <Form.Group className="mt-5" >
                                {
                                    deploySC.errors.map((err,eid) => 
                                        <div className="container mt-5 mb-5" key={eid} >
                                            <div className="alert alert-danger" role="alert">{err.smartContract + " : " + err.error}</div>
                                        </div>
                                    )
                                }
                            </Form.Group>
                        }
                    </div>
                }

                <div>

                        {
                            smartContract === null && deploySC !== null && 
                            <>

                                <div className="container">
                                    <div className="row">
                                        {
                                            categories && categories.map((cat,catid) => 
                                                <div className="col-sm-3" key={catid} >
                                                    <ul className="list-infos">
                                                        <li className="text-uppercase text-color60 mb-1 li-no-bullet">
                                                            <div className="row">
                                                                {cat.name}
                                                            </div>
                                                        </li>
                                                        {
                                                            cat.subcategories && cat.subcategories.map((subcat,subcatid) => 
                                                                <li className="li-no-bullet" key={subcatid} >
                                                                    <i className={( subcat.externalResourcesPath ? subcat.externalResourcesPath[0] : "" ) + " mr-1"}></i>
                                                                    <input type="checkbox" className="mr-3" checked={checkSubtype(subcat.name)} onChange={() => setSubtype(cat.name,subcat.name) } /> {subcat.name}
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>

                                <div className="container">
                                    <Form.Group>
                                        <InputLabel className="text-dark">Smart Contract Source Code</InputLabel>
                                        <Form.Control type="file" className="form-control-file" onChange={handleChange} multiple accept=".sol" />
                                    </Form.Group>

                                    <Form.Group>
                                        <InputLabel className="text-dark" >Title</InputLabel>
                                        <Form.Control value={deploySC.title} onChange={(e) => setDeploySC({...deploySC, title : e.target.value }) } />
                                    </Form.Group>

                                    <Form.Group>
                                        <InputLabel className="text-dark" >Description</InputLabel>
                                        <Form.Control as="textarea" value={deploySC.description} onChange={(e) => setDeploySC({...deploySC, description : e.target.value }) } />
                                    </Form.Group>
                                    
                                    {generateSmartContractStatusUI()}

                                </div>

                                <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom mt-8">
                                    <h1 className="h2">Constructor parameters </h1><h5>( { deploySC.constructorParameters ? deploySC.constructorParameters.length : 0 } smart contracts )</h5>
                                </div>

                                <div className="container">
                                    {
                                        deploySC.constructorParameters.length > 0 && 
                                        <Form.Group className="mt-5" >
                                            <div className="mt-3">
                                                {
                                                    deploySC.constructorParameters.map((sc,id) => 
                                                        <div key={id} className="mt-5 mb-5">
                                                            <div>
                                                                <h5>{handleJsonRowObj(sc).scName}</h5>
                                                            </div>
                                                            {
                                                                handleJsonRowObj(sc).params.length > 0 ? (
                                                                    <div className="mt-5 mb-5">
                                                                        {
                                                                            handleJsonRowObj(sc).params.map((param,pid) => 
                                                                                <div className="row justify-content-center text-center" key={pid} >
                                                                                    <div className="justify-content-center text-center">
                                                                                        <div 
                                                                                            className={"card mt-3 mb-3 ml-3 mr-3 justify-content-center text-center lowPush shadow-lg border-0 " + ( param.type === "address" ? "lowPush clickableCard" : "")} 
                                                                                            style={{"width": "18rem" , "height" : "18rem"}} 
                                                                                            onClick={() => setChooseSmartContract(param,id,handleJsonRowObj(sc).scName,pid)}
                                                                                        >
                                                                                            {
                                                                                                param.type === "address" ? (
                                                                                                    <>
                                                                                                        {
                                                                                                            typeof param.chosenContract === "undefined" && !param.chosenContract ? (
                                                                                                                <>
                                                                                                                    <span className="badge badge-primary shadow-lg adminAddTemplateBadge">{param.codeName}</span>
                                                                                                                    <i className="fas fa-plus fa-5x text-primary"></i>
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    <p>Added , Smart Contract : </p>
                                                                                                                    <p className="font-weight-bold">{param.chosenContract.title}</p>
                                                                                                                    <i className="fas fa-file-contract fa-5x text-success"></i>
                                                                                                                </>
                                                                                                            )
                                                                                                        }

                                                                                                    </>
                                                                                                ) : (
                                                                                                    <div className="container">
                                                                                                        <div className="form-row" >
                                                                                                            <InputLabel className="text-dark" >Code Name</InputLabel>
                                                                                                            <input type="text" value={param.codeName} className="form-control" onChange={(e) => setconstructorParameters({ id : pid , sc : id },"codeName",e.target.value)} readOnly />
                                                                                                        </div>
                                                                                                        <div className="form-row">
                                                                                                            <InputLabel className="text-dark" >Display Name</InputLabel>
                                                                                                            <input type="text" value={param.displayName} className="form-control" onChange={(e) => setconstructorParameters({ id : pid , sc : id },"displayName",e.target.value)} />
                                                                                                        </div>
                                                                                                        <div className="form-row">
                                                                                                            <InputLabel className="text-dark" >Value</InputLabel>
                                                                                                            <input type="text" value={param.value} className="form-control" onChange={(e) => setconstructorParameters({ id : pid , sc : id },"value",e.target.value)} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            }  
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <div className="alert alert-primary" role="alert">
                                                                        This smart contract has no constructor parameter. Very light coded !!
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </Form.Group>
                                    }
                                </div>

                                <div className="container">
                                    {
                                        deploySC.errors.length > 0 && 
                                        <Form.Group className="mt-5" >
                                            <InputLabel className="text-dark" >Errors</InputLabel>
                                                {
                                                    deploySC.errors.map((err,eid) => 
                                                        <div className="container mt-5 mb-5" key={eid} >
                                                            <div className="alert alert-danger" role="alert">{err.smartContract + " : " + err.error}</div>
                                                        </div>
                                                    )
                                                }
                                        </Form.Group>
                                    }
                                </div>

                                <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom mt-8">
                                    <h1 className="h2">Keywords </h1>
                                </div>
                                <div className={classes.root + " row"}>
                                    {
                                        keywords.map((category,categoryId) => 
                                            <div className="col-sm-4" key={categoryId}>
                                                <FormControl component="fieldset" className={classes.formControl}>
                                                    <FormLabel component="legend">{category.category}</FormLabel>
                                                    <FormGroup>
                                                        {
                                                            category.keywords.map((keyword,keywordId) => 
                                                                <FormControlLabel
                                                                    key={keywordId}
                                                                    control={<Checkbox checked={keyword.value} onChange={() => handleCategorySelect(categoryId,keywordId)} value={keyword.label} name={keyword.label} color="primary" />}
                                                                    label={keyword.label}
                                                                />
                                                            )
                                                        }
                                                    </FormGroup>
                                                    <FormHelperText>Choose wisely</FormHelperText>
                                                </FormControl>
                                            </div>
                                        )
                                    }
                                </div>

                                <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom mt-8">
                                    <h1 className="h2">Smart Contract Documentation</h1>
                                </div>

                                <div className="container mt-6">
                                    <Form.Group>
                                        <InputLabel className="text-dark" >Product overview</InputLabel>
                                        <Form.Control as="textarea" value={deploySC.productOverview} onChange={(e) => setDeploySC({ ...deploySC, productOverview : e.target.value })} />
                                    </Form.Group>

                                    {
                                        deploySC.furtherReadingLinks && deploySC.furtherReadingLinks.map((link,linkid) => 
                                            <div className="row" key={linkid} >
                                                <div className="col-sm-6">
                                                    <Form.Group>
                                                        <InputLabel className="text-dark" >Link title</InputLabel>
                                                        <Form.Control value={link.text} onChange={(e) => setLinkProperty(linkid,"text",e.target.value)} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-sm-4">
                                                    <Form.Group>
                                                        <InputLabel className="text-dark" >URL</InputLabel>
                                                        <Form.Control value={link.url} onChange={(e) => setLinkProperty(linkid,"url",e.target.value)} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-sm-2">
                                                    <Button variant="success" className="mr-1" onClick={() => addLink()} ><i className="fas fa-plus"></i></Button>
                                                    {
                                                        deploySC.furtherReadingLinks.length > 1 && 
                                                        <Button variant="danger" onClick={() => removeLink(linkid)} ><i className="fas fa-minus"></i></Button>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }

                                    <Form.Group>
                                        <InputLabel className="text-dark" >Highlights</InputLabel>
                                        <Form.Control as="textarea" value={deploySC.highlights} onChange={(e) => setDeploySC({ ...deploySC, highlights : e.target.value })} />
                                    </Form.Group>

                                    <Form.Group>
                                        <InputLabel className="text-dark" >Implementation time</InputLabel>
                                        <Form.Control value={deploySC.implementationTime} onChange={(e) => setDeploySC({ ...deploySC, implementationTime : e.target.value })} />
                                    </Form.Group>

                                    <Form.Group>
                                        <InputLabel className="text-dark" >Required experience</InputLabel>
                                        <Form.Control value={deploySC.requiredExperience} onChange={(e) => setDeploySC({ ...deploySC, requiredExperience : e.target.value })} />
                                    </Form.Group>
                                </div>

                        </>
                    }

                    {
                        smartContract && typeof smartContract === "object" && smartContract !== null && 
                        <>
                            <div className="container">
                                <div className="row">
                                    {
                                        categories && categories.map((cat,catid) => 
                                            <div className="col-sm-3" key={catid} >
                                                <ul className="list-infos">
                                                    <li className="text-uppercase text-color60 mb-1 li-no-bullet">
                                                        <div className="row">
                                                            {cat.name}
                                                        </div>
                                                    </li>
                                                    {
                                                        cat.subcategories && cat.subcategories.map((subcat,subcatid) => 
                                                            <li className="li-no-bullet" key={subcatid} >
                                                                <i className={( subcat.externalResourcesPath ? subcat.externalResourcesPath[0] : "" ) + " mr-1"}></i>
                                                                <input type="checkbox" className="mr-3" checked={checkSubtype(subcat.name)} onChange={() => setSubtype(cat.name,subcat.name) } /> {subcat.name}
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="container" >
                                <Form.Group>
                                    <InputLabel className="text-dark" >Title</InputLabel>
                                    <Form.Control value={smartContract.title} onChange={(e) => setSmartContract({...smartContract, title : e.target.value }) } />
                                </Form.Group>

                                <Form.Group>
                                    <InputLabel className="text-dark" >Description</InputLabel>
                                    <Form.Control value={smartContract.description} onChange={(e) => setSmartContract({...smartContract, description : e.target.value }) } />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Check type="checkbox" value={smartContract.isPublic} checked={smartContract.isPublic} onChange={() => setSmartContract({...smartContract, isPublic : !smartContract.isPublic }) } label="Public" />
                                </Form.Group>

                                {generateSmartContractStatusUI()}

                                <div className="container">
                                    {
                                        smartContract.smartContracts && smartContract.smartContracts.map((psc,pscId) => 
                                            <div key={pscId}>
                                                <div>
                                                    <h5>{psc.name}</h5>
                                                </div>
                                                {
                                                    psc.constructorParameters.length > 0 ? (
                                                    <>
                                                        <Form.Group className="mt-5" >
                                                            <div className="mt-3">
                                                                {
                                                                    psc.constructorParameters.map((sc,id) => 
                                                                        <div key={id} className="mt-5 mb-5">
                                                                            <div className="mt-5 mb-5">
                                                                                <div className="row justify-content-center text-center" >
                                                                                    <div className="justify-content-center text-center">
                                                                                        <div 
                                                                                            className={"card mt-3 mb-3 ml-3 mr-3 justify-content-center text-center lowPush shadow-lg border-0 " + ( sc.dataType === "address" ? "lowPush clickableCard" : "")} 
                                                                                            style={{"width": "18rem" , "height" : "18rem"}} 
                                                                                            onClick={() => setChooseSmartContract(sc,pscId,psc.name,id)}
                                                                                        >
                                                                                            {
                                                                                                sc.dataType === "address" ? (
                                                                                                    <>
                                                                                                        {
                                                                                                            typeof sc.chosenContract === "undefined" && !sc.chosenContract ? (
                                                                                                                <>
                                                                                                                    <span className="badge badge-primary shadow-lg adminAddTemplateBadge">{sc.codeName}</span>
                                                                                                                    <i className="fas fa-plus fa-5x text-primary"></i>
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    {
                                                                                                                        sc.chosenContract.title ? (
                                                                                                                            <>
                                                                                                                                <p>Added , Smart Contract : </p>
                                                                                                                                <p className="font-weight-bold">{sc.chosenContract.title}</p>
                                                                                                                                <i className="fas fa-file-contract fa-5x text-success"></i>
                                                                                                                            </>
                                                                                                                        ) : (
                                                                                                                            <>
                                                                                                                                <i className="fas fa-file-contract fa-5x text-success"></i>
                                                                                                                            </>
                                                                                                                        )
                                                                                                                    }
                                                                                                                </>
                                                                                                            )
                                                                                                        }

                                                                                                    </>
                                                                                                ) : (
                                                                                                    <div className="container">
                                                                                                        <div className="form-row" >
                                                                                                            <InputLabel className="text-dark" >Code Name</InputLabel>
                                                                                                            <input type="text" value={sc.codeName} className="form-control" onChange={(e) => setconstructorParameters({ id : id , sc : pscId },"codeName",e.target.value)} readOnly />
                                                                                                        </div>
                                                                                                        <div className="form-row">
                                                                                                            <InputLabel className="text-dark" >Display Name</InputLabel>
                                                                                                            <input type="text" value={sc.displayName} className="form-control" onChange={(e) => setconstructorParameters({ id : id , sc : pscId },"displayName",e.target.value)} />
                                                                                                        </div>
                                                                                                        <div className="form-row">
                                                                                                            <InputLabel className="text-dark" >Value</InputLabel>
                                                                                                            <input type="text" value={sc.value} className="form-control" onChange={(e) => setconstructorParameters({ id : id , sc : pscId },"value",e.target.value)} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            }  
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </Form.Group>
                                                    </>
                                                    ) : (
                                                        <div className="alert alert-primary" role="alert">
                                                            This smart contract has no constructor parameter. Very light coded !!
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </div>

                                    <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom mt-8">
                                        <h1 className="h2">Keywords </h1>
                                    </div>
                                    <div className={classes.root + " row"}>
                                        {
                                            keywords.map((category,categoryId) => 
                                                <div className="col-sm-4" key={categoryId}>
                                                    <FormControl component="fieldset" className={classes.formControl}>
                                                        <FormLabel component="legend">{category.category}</FormLabel>
                                                        <FormGroup>
                                                            {
                                                                category.keywords.map((keyword,keywordId) => 
                                                                    <FormControlLabel
                                                                        key={keywordId}
                                                                        control={<Checkbox checked={keyword.value} onChange={() => handleCategorySelect(categoryId,keywordId)} value={keyword.label} name={keyword.label} color="primary" />}
                                                                        label={keyword.label}
                                                                    />
                                                                )
                                                            }
                                                        </FormGroup>
                                                        <FormHelperText>Choose wisely</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            )
                                        }
                                    </div>

                                    <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom mt-8">
                                        <h1 className="h2">Smart Contract Documentation</h1>
                                    </div>

                                    <div className="container mt-6">
                                        <Form.Group>
                                            <InputLabel className="text-dark" >Product overview</InputLabel>
                                            <Form.Control as="textarea" value={smartContract.productOverview} onChange={(e) => setSmartContract({ ...smartContract, productOverview : e.target.value })} />
                                        </Form.Group>

                                        {
                                            smartContract.furtherReadingLinks && smartContract.furtherReadingLinks.map((link,linkid) => 
                                                <div className="row" key={linkid} >
                                                    <div className="col-sm-6">
                                                        <Form.Group>
                                                            <InputLabel className="text-dark" >Link title</InputLabel>
                                                            <Form.Control value={link.text} onChange={(e) => setLinkProperty(linkid,"text",e.target.value)} />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <Form.Group>
                                                            <InputLabel className="text-dark" >URL</InputLabel>
                                                            <Form.Control value={link.url} onChange={(e) => setLinkProperty(linkid,"url",e.target.value)} />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <Button variant="success" className="mr-1" onClick={() => addLink()} ><i className="fas fa-plus"></i></Button>
                                                        {
                                                            smartContract.furtherReadingLinks.length > 1 && 
                                                            <Button variant="danger" onClick={() => removeLink(linkid)} ><i className="fas fa-minus"></i></Button>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }

                                        <Form.Group>
                                            <InputLabel className="text-dark" >Highlights</InputLabel>
                                            <Form.Control as="textarea" value={smartContract.highlights} onChange={(e) => setSmartContract({ ...smartContract, highlights : e.target.value })} />
                                        </Form.Group>

                                        <Form.Group>
                                            <InputLabel className="text-dark" >Implementation time</InputLabel>
                                            <Form.Control value={smartContract.implementationTime} onChange={(e) => setSmartContract({ ...smartContract, implementationTime : e.target.value })} />
                                        </Form.Group>

                                        <Form.Group>
                                            <InputLabel className="text-dark" >Required experience</InputLabel>
                                            <Form.Control value={smartContract.requiredExperience} onChange={(e) => setSmartContract({ ...smartContract, requiredExperience : e.target.value })} />
                                        </Form.Group>
                                    </div>

                            </div>
                        </>
                    }

                    <div className="container mt-5 mb-5 justify-content-center text-center">
                        <div className="row">
                            <div className="col-sm-6">
                                <Link className="btn btn-secondary text-white btn-lg" to="/smartContracts" >Cancel</Link>
                            </div>
                            <div className="col-sm-6">
                                {
                                    deploySC && deploySC !== null && 
                                    <Button variant="success" className="text-light btn-lg" onClick={deploySmartContract} >Deploy</Button>
                                }
                                {
                                    smartContract && typeof smartContract === "object" && smartContract !== null && 
                                    <Button variant="warning" className="text-light btn-lg" onClick={updateSmartContract} >Update</Button>
                                }
                            </div>
                        </div>
                    </div>

                </div>

                <Modal show={isChoosingSmartContract} onHide={hideChoosingSmartContract} size="lg" >
                    <Modal.Header closeButton className="border-0" >
                        <Modal.Title>Choose the smart contract</Modal.Title>
                    </Modal.Header>
                        <Modal.Body className="overflow-wrap-anywhere border-0" >
                            <BootstrapTable data={smartContracts} keyField='_id' striped hover pagination search options={ options } >
                                <TableHeaderColumn dataField='title' dataSort={ true } >Title</TableHeaderColumn>
                                <TableHeaderColumn dataField='description' dataSort={ true } >Description</TableHeaderColumn>
                                <TableHeaderColumn dataField='type' dataSort={ true } >Category</TableHeaderColumn>
                                <TableHeaderColumn dataField='subtype' dataSort={ true } >Subcategory</TableHeaderColumn>
                                <TableHeaderColumn dataFormat={getBlockchainCreationDate} dataField='creationDate' dataSort={ true }>Creation Date</TableHeaderColumn>
                                <TableHeaderColumn dataFormat={operations} >Operations</TableHeaderColumn>
                            </BootstrapTable>
                        </Modal.Body>
                    <Modal.Footer className="border-0" >
                        <Button variant="secondary" className="float-left" onClick={hideChoosingSmartContract}>Cancel</Button>
                    </Modal.Footer>
                </Modal>

            </>
        );
}

export default BlockchainInfos;
