import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputLabel from '@material-ui/core/InputLabel';
import Request from './Config/Request.js';

function AddNode(props) {
    let [blockchainId,setBlockchainId] = useState(props.match.params.blockchainId);
    let [newNode,setNewNode] = useState({ nodeRPCPort : 22000 , nodeNetworkListeningPort : 22001 , nodeConstellationPort : 22002 , nodeRaftPort : 22003 , nodeNodeManagerPort : 22004 , nodeWS : 22005 });
    let [errorCode,setErrorCode] = useState(null);
    let [errorMessage,setErrorMessage] = useState(null);
    let [successMessage,setSuccessMessage] = useState(null);
    let [region,selectRegion] = useState(undefined);
    let [instance,setInstance] = useState(undefined);
    let [user,setUser] = useState(JSON.parse(sessionStorage.getItem('user')));
    let deploy = async () => {
        try{
            if ( typeof newNode.nodeName !== "undefined" && newNode.nodeName.length === 0 ){
                setErrorCode(404);
                setErrorMessage("Please specify a name for this node");
                return;
            }
            let addNode = await Request.h64HttpRequest("POST","/addNewNode",{ blockchainID : blockchainId , node : newNode , region : region , instanceType : instance , accountID : user.h64AccountID });
            setErrorCode(addNode.errorCode);
            setErrorMessage(addNode.errorMessage);
            if ( addNode.errorCode === null && addNode.errorMessage === null )
                setSuccessMessage("Node has been deployed on blockchain " + blockchainId);
        }catch(err){
            console.error(err);
        }
    }
    let chooseRegion = (region) => {
        selectRegion(region);
    }
    let chooseInstance = (instance) => {
        setInstance(instance);
    }
    return (
        <div className="mt-5 mb-5">

            <Form.Group>
                <InputLabel className="text-dark" >Node name</InputLabel>
                <Form.Control value={newNode.nodeName} onChange={(e) => setNewNode({...newNode, nodeName : e.target.value }) } />
            </Form.Group>
            <Form.Group>
                <InputLabel className="text-dark" >Node RPCPort</InputLabel>
                <Form.Control value={newNode.nodeRPCPort} type="number" onChange={(e) => setNewNode({...newNode, nodeRPCPort : Number(e.target.value) , nodeNetworkListeningPort : Number(e.target.value) + 1 , nodeConstellationPort : Number(e.target.value) + 2 , nodeRaftPort : Number(e.target.value) + 3 , nodeNodeManagerPort : Number(e.target.value) + 4 , nodeWS : Number(e.target.value) + 5 }) } />
            </Form.Group>
            <Form.Group>
                <InputLabel className="text-dark" >Node network listening port</InputLabel>
                <Form.Control value={newNode.nodeNetworkListeningPort} type="number" onChange={(e) => setNewNode({...newNode, nodeNetworkListeningPort : Number(e.target.value) }) } />
            </Form.Group>
            <Form.Group>
                <InputLabel className="text-dark" >Node constellation port</InputLabel>
                <Form.Control value={newNode.nodeConstellationPort} type="number" onChange={(e) => setNewNode({...newNode, nodeConstellationPort : Number(e.target.value) }) } />
            </Form.Group>
            <Form.Group>
                <InputLabel className="text-dark" >Node raft port</InputLabel>
                <Form.Control value={newNode.nodeRaftPort} type="number" onChange={(e) => setNewNode({...newNode, nodeRaftPort : Number(e.target.value) }) } />
            </Form.Group>
            <Form.Group>
                <InputLabel className="text-dark" >Node manager port</InputLabel>
                <Form.Control value={newNode.nodeNodeManagerPort} type="number" onChange={(e) => setNewNode({...newNode, nodeNodeManagerPort : Number(e.target.value) }) } />
            </Form.Group>
            <Form.Group>
                <InputLabel className="text-dark" >Node WS</InputLabel>
                <Form.Control value={newNode.nodeWS} onChange={(e) => setNewNode({...newNode, nodeWS : e.target.value }) } />
            </Form.Group>
            <Form.Group className="mt-5">
                <InputLabel className="text-dark" >VPS Region</InputLabel>
                <InputLabel className="text-dark" >(click on the image to select)</InputLabel>
                <div className="container mt-3">
                    <div className="row justify-content-center text-center">
                        <div className="col-sm-6">
                            <div className="regionContainer" onClick={() => chooseRegion("eu-north-1")} >
                                <img src="/eu-north-1.png" className="w-75 h-75" ></img>
                                <p>EU-NORTH-1 ( Stockholm )</p>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="regionContainer" onClick={() => chooseRegion("eu-north-1")} >
                                <img src="/eu-north-1.png" className="w-75 h-75" ></img>
                                <p>EU-NORTH-1 ( Stockholm )</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Form.Group>
            <Form.Group className="mt-5">
                <InputLabel className="text-dark" >VPS Type</InputLabel>
                <InputLabel className="text-dark" >(click to select)</InputLabel>
                <div className="container mt-3">
                    <div className="row justify-content-center text-center">
                        <div className="col-sm-6">
                            <div className="card" style={{"width" : "18rem"}} onClick={() => chooseInstance("t3.micro")}>
                                <div className="card-header">
                                    T3 Micro
                                </div>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">vCPU : 2</li>
                                    <li className="list-group-item">RAM : 1 GiB</li>
                                    <li className="list-group-item">Network performance : Up to 5 Gigabit</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="card" style={{"width" : "18rem"}} onClick={() => chooseInstance("t3.micro")}>
                                <div className="card-header">
                                    T3 Micro
                                </div>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">vCPU : 2</li>
                                    <li className="list-group-item">RAM : 1 GiB</li>
                                    <li className="list-group-item">Network performance : Up to 5 Gigabit</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Form.Group>
            <div className="row justify-content-center mt-5">
                <Button variant="success" className="text-light" onClick={deploy} >Deploy</Button>
            </div>
            
            <div className={"container mt-5 mb-5" + ( successMessage === null ? " d-none" : "")}>
                <div className="alert alert-success" role="alert">{successMessage}</div>
            </div>

            <div className={"container mt-5 mb-5" + ( errorCode === null && errorMessage === null ? " d-none" : "")}>
                <div className="alert alert-danger" role="alert">{errorMessage}</div>
            </div>

        </div>
    );
}

export default AddNode;
