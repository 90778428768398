const axios = require('axios');
const Parameters = require('./Parameters.js');

async function httpRequest(reqMethod,reqURL,reqBody){
    let serverResponse;
    let header = Parameters.reqHeader;
    header.API_KEY = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')).key : undefined;
    try{
        await axios({
            method : reqMethod,
            url : Parameters.API_URL + reqURL,
            data : reqBody,
            headers : header
        })
        .then(function(response){
            serverResponse = response.data;
        })
        .catch(function(err){
            serverResponse = err.response.data;
        });
    }catch(err){
        console.error(err);
    }

    return serverResponse;

}


async function h64HttpRequest(reqMethod,reqURL,reqBody){

    let serverResponse;
    let header = Parameters.reqHeader;
    header.API_KEY = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')).key : undefined;
    try{
        await axios({
            method : reqMethod,
            url : Parameters.H64_API + reqURL,
            data : reqBody,
            headers : header
        })
        .then(function(response){
            serverResponse = response.data;
        })
        .catch(function(err){
            serverResponse = err.response.data;
        });
    }catch(err){
        console.error(err);
    }

    return serverResponse;

}


async function h64HttpRequestFile(reqURL,reqBody){

    let serverResponse;
    let header = Parameters.reqHeaderFile;
    header.headers.API_KEY = JSON.parse(sessionStorage.getItem('user')).key;
    try{
        await axios.post(Parameters.H64_API + reqURL, reqBody, header)
        .then(function(response){
            serverResponse = response.data;
        })
        .catch(function(err){
            serverResponse = err.response.data;
        });
    }catch(err){
        console.error(err);
    }

    return serverResponse;

}


async function httpRequestLog(accountID,operation,status,email,password){
    let request;
    try{
        request = await httpRequest("POST","/logActivity",{ accountID : accountID , operation : operation , status : status , email : email , password : password });
    }catch(err){
        console.error(err);
    }
    return request;
}

module.exports.httpRequest = httpRequest;
module.exports.httpRequestLog = httpRequestLog;
module.exports.h64HttpRequest = h64HttpRequest;
module.exports.h64HttpRequestFile = h64HttpRequestFile;