import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Request from '../Config/Request.js';
import Parameters from '../Config/Parameters.js';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { purple } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ExploreIcon from '@material-ui/icons/Explore';


const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: purple[500],
    },
}));

function AllBlockchain() {

    let [errorCode,setErrorCode] = useState(null);
    let [errorMessage,setErrorMessage] = useState(null);
    let [toDeploy,setToDeploy] = useState(false);
    let [blockchains,setBlockchains] = useState([]);
    let [user,setUser] = useState(JSON.parse(sessionStorage.getItem('user')));
    let [blockchain,setBlockchain] = useState({});
    let [isUpdating,setUpdating] = useState(false);
    let [isCreating,setCreating] = useState(false);
    let [isRemoving,setRemoving] = useState(false);
    const classes = useStyles();

    let getBlockchains = async () => {
        let blockchains = await Request.h64HttpRequest("GET","/blockchains",{});
        return blockchains.blockchains || [];
    }

    let deploySmartContract = async () => {
        let deploy = await Request.h64HttpRequest("POST","/v1/deployContracts/deploy",{
            accountID : user.h64AccountID ,
            blockchainId : blockchain._id ,
            data : toDeploy.bytecode ,
            abi : toDeploy.abi ,
            type : toDeploy.type ,
            subtype : toDeploy.subtype ,
            coinbasePassword : toDeploy.coinbasePassword ,
            coinbaseIndex : toDeploy.coinbaseIndex ,
            blockchainId : toDeploy.blockchainId
        });
        setErrorCode(deploy.errorCode);
        setErrorMessage(deploy.errorMessage);
        if ( deploy.errorCode === null && deploy.errorMessage === null )
            setToDeploy({...toDeploy, deployed : deploy });
    }

    useEffect( () => {
        try{
           const fetch = async () => {
                let blockchains = await getBlockchains();
                setBlockchains(blockchains);
           }
           fetch();
        }catch(err){
            console.error(err);
        }
      },[]);

    const options = {
        clickToEdit: true,
        clickToSelect: true,
    };

    const nodesButtons = (cell, row) => {
        if ( row.nodes.length > 0 )
            return <Link role="button" to={"/blockchainInfos/" + row._id} className="btn btn-primary mr-2 text-light" >{row.nodes.length}</Link>;
        return <></>;
    }

    const smartContractButtons = (cell, row) => {
        return <Link role="button" to={"/smartContractInfos/" + row._id} className="btn btn-primary mr-2 text-light" >View</Link>;
    }

    let getBlockchainCreationDate = (cell, row) => {
        return <>{moment(row.creationData).format("L HH:mm:ss")}</>
    }

    let cellToLink = (cell, row) => {
        return <a href={row.provider} target="_blank" >{row.provider}</a>
    }

    let totalRootContracts = (cell, row) => {
        if ( typeof row.rootContracts !== "undefined" )
            return <>{row.rootContracts.length}</>;
            return <></>;
    }

    let hideUpdate = () => {
        setBlockchain({});
        setUpdating(false);
    }

    let updateBlockchain = async () => {
        let update = await Request.h64HttpRequest("PATCH","/updateBlockchain",{ blockchain : blockchain });
        if ( update.errorCode === null && update.errorMessage === null ){
            let blockchainsUpdated = blockchains;
            for ( let i = 0 , l = blockchainsUpdated.length ; i < l ; ++i )
                if ( blockchainsUpdated[i]._id === blockchain._id )
                    blockchainsUpdated[i] = blockchain;
            setBlockchains(blockchainsUpdated);
            setUpdating(false);
            setBlockchain({});
        }
    }

    let update = (blockchain) => {
        setBlockchain(blockchain);
        setUpdating(true);
    }

    let deploy = (blockchain) => {
        setBlockchain(blockchain);
        setToDeploy({ abi : "" , bytecode : "" , type : "" , subtype : "" , deployed : null , coinbaseIndex : 0 , coinbasePassword : "" , blockchainId : blockchain._id });
    }

    let hideSmartContractDeployment = () => {
        setBlockchain({});
        setToDeploy(false);
    }

    let create = () => {
        setBlockchain({ name : "" , RPCPort : 22000 , listeningPort : 22001 , constellationPort : 22002 , raftPort : 22003 , nodeManagerPort : 22004 , wsPort : 22005 , isPublic : false , containerOnly : false });
        setCreating(true);
    }

    let hideCreateBlockchain = () => {
        setCreating(false);
        setBlockchain({});
    }

    let createBlockchain = async () => {
        try{
            blockchain.accountID = user.h64AccountID;
            let create = await Request.h64HttpRequest("POST","/createBlockchain",blockchain);
            setErrorCode(create.errorCode);
            setErrorMessage(create.errorMessage);
            if ( create.errorCode === null && create.errorMessage === null ){
                let bchains = blockchains;
                bchains.push(create.blockchain);
                setBlockchains(bchains);
            }
            setBlockchain({});
            setCreating(false);
        }catch(err){
            console.error(err);
        }
    }

    let remove = (blockchain) => {
        setBlockchain(blockchain);
        setRemoving(true);
    }

    let hideRemoveBlockchain = () => {
        setBlockchain({});
        setRemoving(false);
    }

    let removeBlockchain = async () => {
        try {
            let deleteBlockchain = await Request.h64HttpRequest("DELETE","/deleteBlockchain",{ blockchainID : blockchain._id });
            setErrorCode(deleteBlockchain.errorCode);
            setErrorMessage(deleteBlockchain.errorMessage);
            if ( deleteBlockchain.errorCode === null && deleteBlockchain.errorMessage === null ){
                let bchains = [];
                for ( let i = 0 , l = blockchains.length ; i < l ; ++i )
                    if ( blockchains[i]._id !== blockchain._id )
                        bchains.push(blockchains[i]);
                setBlockchains(bchains);
            }
            setBlockchain({});
            setRemoving(false);
        }catch(err){
            console.error(err);
        }
    }

    let operations = (cell, row) => {
        return <div className="ml-3" >
            <div className="row">
                <Link className="btn btn-success text-light mr-2" to={"/explorer/" + row._id} ><i className="fa fa-wpexplorer"></i></Link>
                <Button variant="primary" className="text-light" onClick={() => deploy(row)} ><i className="fa fa-arrow-circle-up"></i></Button>
            </div>
            <div className="row mt-2">
                <Button variant="warning" className="text-light mr-2" onClick={() => update(row)} ><i className="fa fa-edit text-light"></i></Button>
                {
                    row.rootBlockchain !== true && 
                    <Button variant="danger" className="text-light" onClick={() => remove(row)} ><i className="fas fa-trash text-light"></i></Button>
                }
            </div>
        </div>;
    }

        return (
            <>
                
                <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                    <h1 className="h2">Blockchains
                        <Link type="button" to="/blockchains/deploy" className="btn btn-primary text-light ml-2 mr-2">Create blockchain</Link>
                    </h1>
                </div>

                <div className={"container mt-5 mb-5" + ( errorCode === null && errorMessage === null ? " d-none" : "")}>
                    <div className="alert alert-danger" role="alert">{errorMessage}</div>
                </div>

                <div className="row justify-content-center">
                    {
                        blockchains.map((blockchain,id) => 
                        <Card className={classes.root + " ml-3 mb-3 mt-3 mr-3 shadow"}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        {blockchain.name[0]}
                                    </Avatar>
                                }
                                action={
                                    <Link to={"/explorer/" + blockchain._id}>
                                        <IconButton aria-label="View blockchain explorer" color="primary">
                                            <ExploreIcon />
                                        </IconButton>
                                    </Link>
                                }
                                title={blockchain.name}
                                subheader={moment(blockchain.creationDate).format('DD-MMM-YYYY mm:ss') + " ( " + moment().diff(moment(blockchain.creationDate),'days') + " days ago )"}
                            />
                            <CardMedia
                                className={classes.media}
                                image={Parameters.H64_API + "/public/blockchains/profilepictures/" + blockchain._id + ".png"}
                                title={blockchain.name}
                            />
                            <CardContent>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    <div className="row mt-2 mb-2">
                                        {blockchain.description || "No description"}
                                    </div>

                                    <div className="row mt-2 mb-2">
                                        <a target="_blank" href={blockchain.provider}>{blockchain.provider}</a>
                                    </div>

                                    <div className="row mt-2 mb-2 font-weight-bold">
                                        <Link to={"/blockchainInfos/" + blockchain._id}>
                                            <span>
                                                {
                                                    blockchain.nodes.length === 1 ? (
                                                        <>{blockchain.nodes.length} node</>
                                                    ) : (
                                                        <>{blockchain.nodes.length} nodes</>
                                                    )
                                                }
                                            </span>
                                        </Link>
                                    </div>
                                </Typography>
                            </CardContent>
                            <CardActions disableSpacing>
                                <IconButton aria-label="Remove blockchain" className={classes.closeButton} color="secondary" onClick={() => remove(blockchain)} >
                                    <DeleteIcon />
                                </IconButton>
                                <Link to={"/blockchains/update/" + blockchain._id}>
                                    <IconButton aria-label="Edit blockchain" color="info" onClick={() => update(blockchain)} >
                                        <EditIcon />
                                    </IconButton>
                                </Link>
                                <IconButton aria-label="Deploy smart contract" color="primary" onClick={() => deploy(blockchain)} >
                                    <AddShoppingCartIcon />
                                </IconButton>
                            </CardActions>
                        </Card>
                        )
                    }
                </div>

                {
                    isUpdating && 
                    <Modal show={isUpdating} onHide={hideUpdate}>
                        <Modal.Header closeButton>
                            <Modal.Title>Blockchain ID #{blockchain._id}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                        <Form.Group>
                            <InputLabel className="text-dark" >Name</InputLabel>
                            <Form.Control value={blockchain.name} onChange={(e) => setBlockchain({...blockchain, name : e.target.value }) } />
                        </Form.Group>

                        <Form.Group>
                            <InputLabel className="text-dark" >Network ID</InputLabel>
                            <Form.Control value={blockchain.networkId} onChange={(e) => setBlockchain({...blockchain, networkId : e.target.value }) } />
                        </Form.Group>

                        <Form.Group>
                            <InputLabel className="text-dark" >Technology</InputLabel>
                            <Form.Control value={blockchain.technology} onChange={(e) => setBlockchain({...blockchain, technology : e.target.value }) } />
                        </Form.Group>

                        <Form.Group>
                            <InputLabel className="text-dark" >Provider</InputLabel>
                            <Form.Control value={blockchain.provider} onChange={(e) => setBlockchain({...blockchain, provider : e.target.value }) } />
                        </Form.Group>

                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" checked={blockchain.isPublic} onChange={(e) => setBlockchain({...blockchain, isPublic : !blockchain.isPublic }) } label={blockchain.isPublic ? "Free to use" : "Private"} />
                        </Form.Group>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" className="float-left" onClick={hideUpdate}>Cancel</Button>
                            <Button variant="warning" className="text-light" onClick={updateBlockchain} >Update</Button>
                        </Modal.Footer>
                    </Modal>
                }

                {
                    toDeploy && 
                        <Modal show={toDeploy} onHide={hideSmartContractDeployment}>
                            <Modal.Header closeButton>
                                <Modal.Title>Smart Contract Deployment</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                            <Form.Group controlId="formBasicCheckbox">
                                <InputLabel className="text-dark" >ABI</InputLabel>
                                <Form.Control value={toDeploy.abi} onChange={(e) => setToDeploy({...toDeploy, abi : e.target.value }) } />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >Bytecode</InputLabel>
                                <Form.Control value={toDeploy.bytecode} onChange={(e) => setToDeploy({...toDeploy, bytecode : e.target.value }) } />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >Type</InputLabel>
                                <Form.Control value={toDeploy.type} onChange={(e) => setToDeploy({...toDeploy, type : e.target.value }) } />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >Subtype</InputLabel>
                                <Form.Control value={toDeploy.subtype} onChange={(e) => setToDeploy({...toDeploy, subtype : e.target.value }) } />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >Coinbase Index</InputLabel>
                                <Form.Control value={toDeploy.coinbaseIndex} onChange={(e) => setToDeploy({...toDeploy, coinbaseIndex : e.target.value }) } />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >Coinbase Password</InputLabel>
                                <Form.Control value={toDeploy.coinbasePassword} onChange={(e) => setToDeploy({...toDeploy, coinbasePassword : e.target.value }) } />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >Blockchain ID</InputLabel>
                                <Form.Control value={toDeploy.blockchainId} onChange={(e) => setToDeploy({...toDeploy, blockchainId : e.target.value }) } />
                            </Form.Group>

                            {
                                toDeploy.deployed !== null && 
                                <>
                                    <p>Smart contract deployed on blockchain : #{toDeploy.deployed.result.blockchainId}</p>
                                    <p>Application : #{toDeploy.deployed.result.applicationId}</p>
                                    <p>Type : {toDeploy.deployed.result.type}</p>
                                    <p>Subtype : {toDeploy.deployed.result.subtype}</p>
                                    <p><b>Smart Contract address : {toDeploy.deployed.result.contractAddress}</b></p>
                                </>
                            }

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className="float-left" onClick={hideSmartContractDeployment}>Cancel</Button>
                                {
                                    toDeploy.deployed === null && 
                                    <Button variant="success" className="text-light" onClick={deploySmartContract} >Deploy</Button>
                                }
                            </Modal.Footer>
                        </Modal>
                }

                {
                    isCreating && 
                        <Modal show={isCreating} onHide={hideCreateBlockchain}>
                            <Modal.Header closeButton>
                                <Modal.Title>Create a blockchain</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <Form.Group>
                                    <InputLabel className="text-dark" >Name</InputLabel>
                                    <Form.Control value={blockchain.name} onChange={(e) => setBlockchain({...blockchain, name : e.target.value }) } />
                                </Form.Group>

                                <Form.Group>
                                    <InputLabel className="text-dark" >RPC port</InputLabel>
                                    <Form.Control value={blockchain.RPCPort} onChange={(e) => setBlockchain({...blockchain, RPCPort : Number(e.target.value) , listeningPort : Number(e.target.value) + 1 , constellationPort : Number(e.target.value) + 2 , raftPort : Number(e.target.value) + 3 , nodeManagerPort : Number(e.target.value) + 4 , wsPort : Number(e.target.value) + 5 })} />
                                </Form.Group>

                                <Form.Group>
                                    <InputLabel className="text-dark" >Listening port</InputLabel>
                                    <Form.Control value={blockchain.listeningPort} onChange={(e) => setBlockchain({...blockchain, listeningPort : Number(e.target.value) }) } />
                                </Form.Group>

                                <Form.Group>
                                    <InputLabel className="text-dark" >Constellation port</InputLabel>
                                    <Form.Control value={blockchain.constellationPort} onChange={(e) => setBlockchain({...blockchain, constellationPort : Number(e.target.value) }) } />
                                </Form.Group>

                                <Form.Group>
                                    <InputLabel className="text-dark" >Raft port</InputLabel>
                                    <Form.Control value={blockchain.raftPort} onChange={(e) => setBlockchain({...blockchain, raftPort : Number(e.target.value) }) } />
                                </Form.Group>

                                <Form.Group>
                                    <InputLabel className="text-dark" >Node manager port</InputLabel>
                                    <Form.Control value={blockchain.nodeManagerPort} onChange={(e) => setBlockchain({...blockchain, nodeManagerPort : Number(e.target.value) }) } />
                                </Form.Group>

                                <Form.Group>
                                    <InputLabel className="text-dark" >WS port</InputLabel>
                                    <Form.Control value={blockchain.wsPort} onChange={(e) => setBlockchain({...blockchain, wsPort : e.target.value }) } />
                                </Form.Group>

                                <Form.Group controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" checked={blockchain.isPublic} onChange={(e) => setBlockchain({...blockchain, isPublic : !blockchain.isPublic }) } label="Free to use" />
                                </Form.Group>

                                <Form.Group controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" checked={blockchain.containerOnly} onChange={(e) => setBlockchain({...blockchain, containerOnly : !blockchain.containerOnly }) } label="Blockchain container only" />
                                </Form.Group>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className="float-left" onClick={hideCreateBlockchain}>Cancel</Button>
                                <Button variant="success" className="text-light" onClick={createBlockchain} >Create</Button>
                            </Modal.Footer>
                        </Modal>
                }

                {
                    isRemoving && 
                    <Modal show={isRemoving} onHide={hideRemoveBlockchain}>
                        <Modal.Header closeButton>
                            <Modal.Title>You're about to delete a blockchain</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <Form.Group controlId="formBasicCheckbox">
                                <InputLabel className="text-dark" >Are you sure you want to remove blockchain {blockchain.name} ?</InputLabel>
                            </Form.Group>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" className="float-left" onClick={hideRemoveBlockchain}>Cancel</Button>
                            <Button variant="danger" className="text-light" onClick={removeBlockchain} >Remove</Button>
                        </Modal.Footer>
                    </Modal>
                }

            </>
        );
}
 
export default AllBlockchain;
