import React , { useState , useEffect } from 'react';
import Request from '../Config/Request.js';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputLabel from '@material-ui/core/InputLabel';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import StorageIcon from '@material-ui/icons/Storage';
import EqualizerIcon from '@material-ui/icons/Equalizer';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}
  
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
}));


function BlockchainInfos(props) {
    let [errorCode,setErrorCode] = useState(null);
    let [errorMessage,setErrorMessage] = useState(null);
    let [blockchainId,setBlockchainId] = useState(props.match.params.blockchainId);
    let [blockchain,setBlockchain] = useState({});
    let [bodyToView,setBodyToView] = useState(0);
    let [node,setNode] = useState({});
    let [user,setUser] = useState(JSON.parse(sessionStorage.getItem('user')));
    let [isUpdating,setUpdating] = useState(false);
    let [isRemoving,setRemoving] = useState(false);
    let [isViewingVPSInfos,setViewVPSInfos] = useState(false);
    let [sureToReboot,setSureToReboot] = useState(false);
    let [vpsInfos,setVpsInfos] = useState({});
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };


    let getBlockchain = async () => {
        let blockchain = await Request.h64HttpRequest("GET","/blockchain/" + blockchainId,{});
        if ( typeof blockchain.blockchain !== "undefined" )
            return blockchain.blockchain;
        return [];
    }

    useEffect( () => {
        try{
            const fetch = async () => {
                let blockchain = await getBlockchain();
                setBlockchain(blockchain);
            }
            fetch();
        }catch(err){
            console.error(err);
        }
    },[]);

    let dashboardRender = (cell, row) => {
        return <a href={row.dashboard} target="_blank">{row.dashboard}</a>;
    }

    let providerRender = (cell, row) => {
        return <a href={row.provider} target="_blank">{row.provider}</a>;
    }

    let update = (node) => {
        setNode(node);
        setUpdating(true);
    }

    let rebootNode = async () => {
        let reboot = await Request.httpRequest("GET","/rebootVPS/" + user.accountID + "/" + user.passwordRaw + "/" + node.VPSName);
    }

    let reboot = (node) => {
        setNode(node);
        setSureToReboot(true);
    }

    let hideSureToReboot = () => {
        setNode({});
        setSureToReboot(false);
    }

    let hideUpdate = () => {
        setNode({});
        setUpdating(false);
    }

    const options = {
        clickToEdit: true,
        clickToSelect: true,
    };

    let setVPSInfos = (node) => {
        setNode(node);
        setViewVPSInfos(true);
        getVPSInfos(node);
    }

    let remove = (node) => {
        setNode(node);
        setRemoving(true);
    }

    let buttons = (cell, row) => {
        return <div className="ml-3" >
            <div className="row">
                <Button variant="danger" className="text-light" onClick={() => remove(row)} ><i className="fa fa-trash" aria-hidden="true"></i></Button>
                <Button variant="primary" className="text-light ml-2" onClick={() => setVPSInfos(row)} ><i className="fa fa-industry" aria-hidden="true"></i></Button>
            </div>
            <div className="row mt-2">
                <Button variant="warning" className="text-light" onClick={() => update(row)} ><i className="fa fa-edit" aria-hidden="true"></i></Button>
                <Button variant="danger" className="text-light ml-2" onClick={() => reboot(row)} ><i className="fa fa-refresh" aria-hidden="true"></i></Button>
            </div>
        </div>;
    }

    let hideVPSInfos = () => {
        setNode({});
        setViewVPSInfos(false);
    }

    let updateNode = async () => {
        let update = await Request.h64HttpRequest("PATCH","/updateNode",{ blockchainID : blockchainId , nodeId : node._id , node : node });
        if ( update.errorCode === null && update.errorMessage === null ){
            let blockchainUpdated = blockchain;
            for ( let i = 0 , l = blockchainUpdated.nodes.length ; i < l ; ++i )
                if ( blockchainUpdated.nodes[i]._id === node._id )
                    blockchainUpdated.nodes[i] = node;
            setBlockchain(blockchainUpdated);
            setUpdating(false);
            setNode({});
        }
    }

    let hideSureToRemove = () => {
        setNode({});
        setRemoving(false);
    }

    let removeNode = async () => {
        let remove = await Request.h64HttpRequest("DELETE","/deleteNode",{ blockchainID : blockchainId , nodeID : node._id });
        setErrorCode(remove.errorCode);
        setErrorMessage(remove.errorMessage);
        if ( remove.errorCode === null && remove.errorMessage === null ){
            let bchain = blockchain;
            let newNodes = [];
            for ( let i = 0 , l = bchain.nodes.length ; i < l ; ++i )
                if ( bchain.nodes[i]._id !== node._id )
                    newNodes.push(bchain.nodes[i]);
            bchain.nodes = newNodes;
            setBlockchain(bchain);
        }
        setRemoving(false);
        setNode({});
    }

    let getVPSInfos = async (node) => {
        let getVPSInfos = await Request.httpRequest("GET","/getVPSInfos/" + user._id + "/" + user.password + "/" + node.VPSName,{});
        setVpsInfos(getVPSInfos.infos);
    }

        return (
            <>

                <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                    <h1 className="h2">All blockchain infos
                    <Link to={"/addNode/" + blockchainId} role="button" className="btn btn-primary text-light ml-2">Add node</Link>
                    </h1>
                </div>

                <div className={"container mt-5 mb-5" + ( errorCode === null && errorMessage === null ? " d-none" : "")}>
                    <div className="alert alert-danger" role="alert">{errorMessage}</div>
                </div>

                <div className={classes.root}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="on"
                            indicatorColor="primary"
                            textColor="primary"
                        >
                        <Tab label="General" icon={<ImportContactsIcon />} {...a11yProps(0)} />
                        <Tab label="Statistics" icon={<EqualizerIcon />} {...a11yProps(1)} />
                        <Tab label={"Nodes" + ( blockchain.nodes !== undefined ? "( " + blockchain.nodes.length + " )" : "" )} icon={<StorageIcon />} {...a11yProps(2)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <Card.Title>General infos</Card.Title>
                        <Card.Text># ID : {blockchain._id}</Card.Text>
                        <Card.Text>Name : {blockchain.name}</Card.Text>
                        <Card.Text>{blockchain.isPublic ? 'PUBLIC BLOCKCHAIN' : 'PRIVATE BLOCKCHAIN'}</Card.Text>
                        <Card.Text>Network ID : {blockchain.networkId}</Card.Text>
                        <Card.Text>Technology : {blockchain.technology}</Card.Text>
                        <Card.Text>Provider : <a href={blockchain.provider} target="_blank">{blockchain.provider}</a></Card.Text>
                        <Card.Text>Keywords : {JSON.stringify(blockchain.keywords)}</Card.Text>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Card.Title>Statistics</Card.Title>
                        <Card.Text>Created on : {moment(blockchain.creationDate).format("L HH:mm:ss")}</Card.Text>
                        <Card.Text>Total mined blocks : {blockchain.blocks}</Card.Text>
                        <Card.Text>Total events : {blockchain.events}</Card.Text>
                        <Card.Text>Total transactions : {blockchain.transactions}</Card.Text>
                        <Card.Text>Total events : {blockchain.events}</Card.Text>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Card.Title>Nodes</Card.Title>
                        <BootstrapTable data={blockchain.nodes} keyField='VPSName' striped hover pagination search options={ options } >
                            <TableHeaderColumn dataField='VPSName' dataSort={ true }>VPS Name</TableHeaderColumn>
                            <TableHeaderColumn dataFormat={ dashboardRender} >Dashboard</TableHeaderColumn>
                            <TableHeaderColumn dataField='nodeIPv4' dataSort={ true }>IPv4</TableHeaderColumn>
                            <TableHeaderColumn dataField='nodeName' dataSort={ true }>Name</TableHeaderColumn>
                            <TableHeaderColumn dataFormat={ buttons } >Operations</TableHeaderColumn>
                        </BootstrapTable>
                    </TabPanel>
                </div>

                {
                    node && 
                    <>
                        <Modal show={isUpdating} onHide={hideUpdate}>
                            <Modal.Header closeButton>
                                <Modal.Title>Node ID #{node._id}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <Form.Group>
                                    <InputLabel className="text-dark" >Node name</InputLabel>
                                    <Form.Control value={node.nodeName} onChange={(e) => setNode({...node, nodeName : e.target.value }) } />
                                </Form.Group>

                                <Form.Group>
                                    <InputLabel className="text-dark" >VPS Name</InputLabel>
                                    <Form.Control value={node.VPSName} onChange={(e) => setNode({...node, VPSName : e.target.value }) } />
                                </Form.Group>

                                <Form.Group>
                                    <InputLabel className="text-dark" >VPS Localisation</InputLabel>
                                    <Form.Control value={node.VPSlocalisation} onChange={(e) => setNode({...node, VPSlocalisation : e.target.value }) } />
                                </Form.Group>

                                <Form.Group>
                                    <InputLabel className="text-dark" >VPS Login</InputLabel>
                                    <Form.Control value={node.VPSLogin} onChange={(e) => setNode({...node, VPSLogin : e.target.value }) } />
                                </Form.Group>

                                <Form.Group>
                                    <InputLabel className="text-dark" >VPS Password</InputLabel>
                                    <Form.Control value={node.VPSPassword} onChange={(e) => setNode({...node, VPSPassword : e.target.value }) } />
                                </Form.Group>

                                <Form.Group>
                                    <InputLabel className="text-dark" >Node IPv4</InputLabel>
                                    <Form.Control value={node.nodeIPv4} onChange={(e) => setNode({...node, nodeIPv4 : e.target.value }) } />
                                </Form.Group>

                                <Form.Group>
                                    <InputLabel className="text-dark" >VPS port</InputLabel>
                                    <Form.Control value={node.VPSPort} onChange={(e) => setNode({...node, VPSPort : e.target.value }) } />
                                </Form.Group>

                                <Form.Group>
                                    <InputLabel className="text-dark" >Node RPC port</InputLabel>
                                    <Form.Control value={node.nodeRPCPort} onChange={(e) => setNode({...node, nodeRPCPort : e.target.value }) } />
                                </Form.Group>

                                <Form.Group>
                                    <InputLabel className="text-dark" >Node network listening port</InputLabel>
                                    <Form.Control value={node.nodeNetworkListeningPort} onChange={(e) => setNode({...node, nodeNetworkListeningPort : e.target.value }) } />
                                </Form.Group>

                                <Form.Group>
                                    <InputLabel className="text-dark" >Node constellation port</InputLabel>
                                    <Form.Control value={node.nodeConstellationPort} onChange={(e) => setNode({...node, nodeConstellationPort : e.target.value }) } />
                                </Form.Group>

                                <Form.Group>
                                    <InputLabel className="text-dark" >Node Raft port</InputLabel>
                                    <Form.Control value={node.nodeRaftPort} onChange={(e) => setNode({...node, nodeRaftPort : e.target.value }) } />
                                </Form.Group>

                                <Form.Group>
                                    <InputLabel className="text-dark" >Node manager port</InputLabel>
                                    <Form.Control value={node.nodeNodeManagerPort} onChange={(e) => setNode({...node, nodeNodeManagerPort : e.target.value }) } />
                                </Form.Group>

                                <Form.Group>
                                    <InputLabel className="text-dark" >Node WS</InputLabel>
                                    <Form.Control value={node.nodeWS} onChange={(e) => setNode({...node, nodeWS : e.target.value }) } />
                                </Form.Group>

                                <Form.Group>
                                    <InputLabel className="text-dark" >Dashboard</InputLabel>
                                    <Form.Control value={node.dashboard} onChange={(e) => setNode({...node, dashboard : e.target.value }) } />
                                </Form.Group>

                                <Form.Group>
                                    <InputLabel className="text-dark" >Provider</InputLabel>
                                    <Form.Control value={node.provider} onChange={(e) => setNode({...node, provider : e.target.value }) } />
                                </Form.Group>

                                <Form.Group>
                                    <InputLabel className="text-dark" >Node public key</InputLabel>
                                    <Form.Control value={node.nodePublicKey} onChange={(e) => setNode({...node, nodePublicKey : e.target.value }) } />
                                </Form.Group>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className="float-left" onClick={hideUpdate}>Cancel</Button>
                                <Button variant="warning" className="text-light" onClick={updateNode} >Ok</Button>
                            </Modal.Footer>
                        </Modal>

                        {
                            isViewingVPSInfos && 
                            <Modal show={isViewingVPSInfos} onHide={hideVPSInfos}>
                            <Modal.Header closeButton>
                                <Modal.Title>Node ID #{node._id}</Modal.Title>
                            </Modal.Header>
                                <Modal.Body className="overflow-wrap-anywhere" >
                                    {
                                        typeof vpsInfos.displayName !== "undefined" && 
                                        <>
                                            <Form.Group>
                                                <InputLabel className="text-dark" >Display Name : {vpsInfos.displayName}</InputLabel>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicCheckbox">
                                                <InputLabel className="text-dark" >SLA Monitoring : 
                                                    { typeof vpsInfos.slaMonitoring !== "undefined" && 
                                                        <>{vpsInfos.slaMonitoring ? "YES" : "NO"}</>
                                                    }
                                                </InputLabel>
                                            </Form.Group>
                                            <Form.Group>
                                                <InputLabel className="text-dark" >Cores : {vpsInfos.vcore}</InputLabel>
                                            </Form.Group>
                                            <Form.Group>
                                                <InputLabel className="text-dark" >Memory Limit : {vpsInfos.memoryLimit}</InputLabel>
                                            </Form.Group>
                                            <Form.Group>
                                                <InputLabel className="text-dark" >Maximum Additionnal IP : 
                                                    {
                                                        typeof vpsInfos.model !== "undefined" && 
                                                        <>
                                                            <br/>
                                                            {vpsInfos.model.maximumAdditionnalIp}
                                                        </>
                                                    } 
                                                </InputLabel>
                                            </Form.Group>
                                            <Form.Group>
                                                <InputLabel className="text-dark" >AvailableOptions : 
                                                {
                                                    typeof vpsInfos.model !== "undefined" && 
                                                    <>
                                                        <br/>
                                                        {String(JSON.stringify(vpsInfos.model.availableOptions)).slice(1,String(JSON.stringify(vpsInfos.model.availableOptions)).length-1)}
                                                    </>
                                                } 
                                                </InputLabel>
                                            </Form.Group>
                                            <Form.Group>
                                                <InputLabel className="text-dark" >Blocked IP addresses : 
                                                {
                                                    typeof vpsInfos.monitoringIpBlocks !== "undefined" && 
                                                    <>
                                                        {String(JSON.stringify(vpsInfos.monitoringIpBlocks)).slice(1,String(JSON.stringify(vpsInfos.monitoringIpBlocks)).length-1)}
                                                    </>
                                                }
                                                </InputLabel>
                                            </Form.Group>
                                            <Form.Group>
                                                <InputLabel className="text-dark" >Disk : 
                                                {
                                                    typeof vpsInfos.model !== "undefined" && 
                                                    <>
                                                        {vpsInfos.model.disk}
                                                    </>
                                                }
                                                </InputLabel>
                                            </Form.Group>
                                            <Form.Group>
                                                <InputLabel className="text-dark" >State : {vpsInfos.state}</InputLabel>
                                            </Form.Group>
                                            <Form.Group>
                                                <InputLabel className="text-dark" >Net boot mode : {vpsInfos.netbootMode}</InputLabel>
                                            </Form.Group>
                                            <Form.Group>
                                                <InputLabel className="text-dark" >Zone : {vpsInfos.zone}</InputLabel>
                                            </Form.Group>
                                            <Form.Group>
                                                <InputLabel className="text-dark" >Cluster : {vpsInfos.cluster}</InputLabel>
                                            </Form.Group>
                                            <Form.Group>
                                                <InputLabel className="text-dark" >Name : {vpsInfos.name}</InputLabel>
                                            </Form.Group>
                                            <Form.Group>
                                                <InputLabel className="text-dark" >Data center : 
                                                {
                                                    typeof vpsInfos.model !== "undefined" && 
                                                    <>
                                                        {String(JSON.stringify(vpsInfos.model.datacenter)).slice(1,String(JSON.stringify(vpsInfos.model.datacenter)).length-1)}
                                                    </>
                                                }
                                                </InputLabel>
                                            </Form.Group>
                                        </>
                                    }
                                    {
                                        typeof vpsInfos.displayName === "undefined" && 
                                        <div className="text-center">
                                            <h5>Wait a few moments , we're getting the VPS infos for you</h5>
                                        </div>
                                    }
                                </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className="float-left" onClick={hideVPSInfos}>Cancel</Button>
                            </Modal.Footer>
                        </Modal>
                        }


                        <Modal show={sureToReboot} onHide={hideSureToReboot}>
                            <Modal.Header closeButton>
                                <Modal.Title>Node ID #{node._id}</Modal.Title>
                            </Modal.Header>
                                <Modal.Body className="overflow-wrap-anywhere" >
                                    Are you sure you want to reboot this node ?
                                </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className="float-left" onClick={hideSureToReboot}>Cancel</Button>
                                <Button variant="danger" className="float-left" onClick={rebootNode}>Reboot</Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={isRemoving} onHide={hideSureToRemove}>
                            <Modal.Header closeButton>
                                <Modal.Title>Node ID #{node._id}</Modal.Title>
                            </Modal.Header>
                                <Modal.Body className="overflow-wrap-anywhere" >
                                    Are you sure you want to remove this node ?
                                </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className="float-left" onClick={hideSureToRemove}>Cancel</Button>
                                <Button variant="danger" className="float-left" onClick={removeNode}>Remove</Button>
                            </Modal.Footer>
                        </Modal>

                    </>
                }
            </>
        );
}

export default BlockchainInfos;
