import React from 'react';
import clsx from 'clsx';
import { Link, Route, Switch } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Home from './Home.js';
import ForgotPassword from './ForgotPassword.js';
import AdminSignIn from './AdminSignIn.js';
import AllLogs from './AllLogs.js';
import AllBlockchains from './Blockchains/AllBlockchain.js';
import AllUsers from './AllUsers.js';
import BlockchainInfos from './Blockchains/BlockchainInfos.js';
import DeployBlockchain from './Blockchains/DeployBlockchain.js';
import Explorer from './Explorer.js';
import SmartContractInfos from './SmartContractInfos.js';
import SmartContracts from './SmartContracts.js';
import Applications from './Applications.js';
import AddNode from './AddNode.js';
import Categories from './Categories.js';
import HandleSmartContract from './HandleSmartContract.js';
import Order from './Orders.js';
import Keywords from './Keywords.js';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AirplayIcon from '@material-ui/icons/Airplay';
import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';
import AppsIcon from '@material-ui/icons/Apps';
import WidgetsIcon from '@material-ui/icons/Widgets';
import ListIcon from '@material-ui/icons/List';
import DescriptionIcon from '@material-ui/icons/Description';
import CategoryIcon from '@material-ui/icons/Category';
import LabelIcon from '@material-ui/icons/Label';
import AssignmentIcon from '@material-ui/icons/Assignment';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  let user = JSON.parse(sessionStorage.getItem('user'));

   let handleDrawerOpen = () => {
      setOpen(true);
   };

   let handleDrawerClose = () => {
      setOpen(false);
   };

   let logOut = () => {
      sessionStorage.clear();
      window.location.href="/";
   }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Console Admin
          </Typography>
          <List>
            {
              user && user.h64AccountID ? (
                <Link to="/home" className="text-decoration-none text-white ml-4" >
                  <HomeIcon/>
                </Link>
              ) : (
                <Link to="/" className="text-decoration-none text-white ml-4" >
                  <HomeIcon/>
                </Link>
              )
            }
          </List>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
            {
               user ? (
                  <>
                    <Divider />
                    <List>
                      <Link to="/home" className="text-decoration-none" >
                        <ListItem button>
                            <ListItemIcon><AirplayIcon/></ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItem>
                      </Link>
                      <Link to="/users" className="text-decoration-none" >
                        <ListItem button>
                            <ListItemIcon><GroupIcon/></ListItemIcon>
                            <ListItemText primary="Users" />
                        </ListItem>
                      </Link>
                      <Link to="/logs" className="text-decoration-none" >
                        <ListItem button>
                            <ListItemIcon><ListIcon/></ListItemIcon>
                            <ListItemText primary="Logs" />
                        </ListItem>
                      </Link>
                      <Link to="/blockchains" className="text-decoration-none" >
                        <ListItem button>
                            <ListItemIcon><WidgetsIcon/></ListItemIcon>
                            <ListItemText primary="Blockchains" />
                        </ListItem>
                      </Link>
                      <Link to="/applications" className="text-decoration-none" >
                        <ListItem button>
                            <ListItemIcon><AppsIcon/></ListItemIcon>
                            <ListItemText primary="Applications" />
                        </ListItem>
                      </Link>
                      <Link to="/orders" className="text-decoration-none" >
                        <ListItem button>
                            <ListItemIcon><AssignmentIcon/></ListItemIcon>
                            <ListItemText primary="Orders" />
                        </ListItem>
                      </Link>
                      <Link to="/smartContracts" className="text-decoration-none" >
                        <ListItem button>
                            <ListItemIcon><DescriptionIcon/></ListItemIcon>
                            <ListItemText primary="Smart Contracts" />
                        </ListItem>
                      </Link>
                      <Link to="/keywords" className="text-decoration-none" >
                        <ListItem button>
                            <ListItemIcon><LabelIcon/></ListItemIcon>
                            <ListItemText primary="Keywords" />
                        </ListItem>
                      </Link>
                      <Link to="/categories" className="text-decoration-none" >
                        <ListItem button>
                            <ListItemIcon><CategoryIcon/></ListItemIcon>
                            <ListItemText primary="Categories" />
                        </ListItem>
                      </Link>
                    </List>
                     <Divider />
                     <List>
                        <ListItem button onClick={logOut}>
                          <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                          <ListItemText primary="Sign out" />
                        </ListItem>
                     </List>
                  </>
               ) : (
                  <>
                     <Divider />
                     <List>
                        <Link to="/" className="text-decoration-none" >
                           <ListItem button>
                              <ListItemIcon><HomeIcon/></ListItemIcon>
                              <ListItemText primary="Homepage" />
                           </ListItem>
                        </Link>
                     </List>
                  </>
               )
            }

      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Switch>
          <Route path="/home" component={Home} exact/>
          <Route path="/forgotPassword" component={ForgotPassword}/>
          <Route path="/" exact component={AdminSignIn}/>
          <Route path="/signin" exact component={AdminSignIn}/>
          <Route path="/logs" exact component={AllLogs}/>
          <Route path="/users" exact component={AllUsers}/>
          <Route path="/blockchains" exact component={AllBlockchains}/>
          <Route path="/blockchainInfos/:blockchainId" exact component={BlockchainInfos}/>
          <Route path="/explorer/:blockchainId" exact component={Explorer}/>
          <Route path="/smartContractInfos/:blockchainId" exact component={SmartContractInfos}/>
          <Route path="/smartContracts" exact component={SmartContracts} />
          <Route path="/applications" exact component={Applications} />
          <Route path="/addNode/:blockchainId" exact component={AddNode} />
          <Route path="/categories" exact component={Categories} />
          <Route path="/handleSmartContract/:smartContractId" exact component={HandleSmartContract} />
          <Route path="/orders" exact component={Order} />
          <Route path="/blockchains/deploy" exact component={DeployBlockchain} />
          <Route path="/blockchains/update/:blockchainId" exact component={DeployBlockchain} />
          <Route path="/keywords" exact component={Keywords} />
        </Switch>

      </main>
    </div>
  );
}
