import React, { useState , useEffect } from 'react';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputLabel from '@material-ui/core/InputLabel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Parameters from './Config/Parameters.js';
import Modal from 'react-bootstrap/Modal';
import Request from './Config/Request.js';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import getCategories from './Common/getCategories.js';


function Categories() {

    let [errorCode,setErrorCode] = useState(null);
    let [errorMessage,setErrorMessage] = useState(null);
    let [categories,setCategories] = useState([]);
    let [user,setUser] = useState(JSON.parse(sessionStorage.getItem('user')));
    let [isUpdatingCategory,setUpdatingCategory] = useState(false);
    let [isCreatingCategory,setCreatingCategory] = useState(false);
    let [isViewingSubcategories,setViewingSubcategories] = useState(false);
    let [categoryToInteract,setCategoryToInteract] = useState(null);
    let [isDeletingCategory,setDeletingCategory] = useState(false);
    let [isViewingCategory,setViewingCategory] = useState(false);

    let updateCategory = async () => {
        let updateTheCategory = await Request.httpRequest("PUT","/updateCategory",{ category : categoryToInteract });
        if ( updateTheCategory ){
            setErrorCode(updateTheCategory.errorCode);
            setErrorMessage(updateTheCategory.errorMessage);
            if ( updateTheCategory.errorCode === null && updateTheCategory.errorMessage === null ){
                let newCategories = categories;
                for ( let i = 0 , l = newCategories.length ; i < l ; ++i )
                    if ( newCategories[i]._id === categoryToInteract._id ){
                        newCategories[i] = categoryToInteract;
                        break;
                    }
                setCategories(newCategories);
                cancelCategoryInteraction();
            }
        }
    }

    let cancelCategoryInteraction = () => {
        setCreatingCategory(false);
        setUpdatingCategory(false);
        setUpdatingCategory(false);
        setViewingCategory(false);
        setViewingSubcategories(false);
        setCategoryToInteract(null);
    }

    useEffect( () => {
        const fetchData = async () =>  {
            const theCategories = await getCategories();
            setCategories(theCategories);
        }
        fetchData();
    },[]);

    let setViewingSubcategoriesModal = (category) => {
        if ( category.subcategories.length > 0 ){
            setCategoryToInteract(category);
            setViewingSubcategories(true);
        }
    }

    let setDeletingCategoryModal = (category) => {
        setCategoryToInteract(category);
        setDeletingCategory(true);
    }

    let setUpdatingCategoryModal = (category) => {
        setCategoryToInteract(category);
        setUpdatingCategory(true);
    }

    let setViewingCategoryModal = (category) => {
        setCategoryToInteract(category);
        setViewingCategory(true);
    }

    let createCategory = async () => {
        let addCategory = await Request.httpRequest("POST","/createCategory",{ category : categoryToInteract });
        if ( addCategory ){
            setErrorCode(addCategory.errorCode);
            setErrorMessage(addCategory.errorMessage);
            if ( addCategory.errorCode === null && addCategory.errorMessage === null ){
                setCategories([ ...categories, addCategory.category ]);
                cancelCategoryInteraction();
            }
        }
    }

    let addSubcategory = () => {
        let newSubcategories = categoryToInteract.subcategories;
        newSubcategories.push({ name : "" , description : "" , externalResourcesPath : [] });
        setCategoryToInteract({ ...categoryToInteract, subcategories : newSubcategories });
    }

    let openCreateCategoryModal = () => {
        setCategoryToInteract({ name : "", description : "" , subcategories : [] , externalResourcesPath : [] });
        setCreatingCategory(true);
    }

    let setSubcategory = (id,prop,value) => {
        let newSubcategories = categoryToInteract.subcategories;
        newSubcategories[id][prop] = value;
        setCategoryToInteract({ ...categoryToInteract, subcategories : newSubcategories });
    }

    let formatSubcategories = (cell,row) => {
        return <button className="btn btn-link" onClick={() => setViewingSubcategoriesModal(row)} >{row.subcategories.length}</button>;
    }

    let categoryOperations = (cell,row) => {
        return <>
            <div className="row mb-2 ml-3">
                <Button variant="primary" className="mr-2" onClick={() => setViewingCategoryModal(row)} >View</Button>
                <Button variant="warning" className="text-white" onClick={() => setUpdatingCategoryModal(row)} >Update</Button>
            </div>
            <div className="row ml-3">
                <Button variant="danger" className="mr-2" onClick={() => setDeletingCategoryModal(row)} >Delete</Button>
            </div>
        </>;
    }

    let deleteCategory = async () => {
        let deleteTheCategory = await Request.httpRequest("DELETE","/deleteCategory",{ categoryId : categoryToInteract._id });
        if ( deleteTheCategory ){
            setErrorCode(deleteTheCategory.errorCode);
            setErrorMessage(deleteTheCategory.errorMessage);
            if ( deleteTheCategory.errorCode === null && deleteTheCategory.errorMessage === null ){
                let newCategories = [];
                for ( let i = 0 , l = categories.length ; i < l ; ++i )
                    if ( categories[i]._id !== categoryToInteract._id )
                        newCategories.push(categories[i]);
                setCategories(newCategories);
                cancelCategoryInteraction();
            }
        }
    }

    let setCategoryToInteractExternalResourcesPath = (input) => {
        let categoryToInteractExternalResourcesPath = categoryToInteract.externalResourcesPath || [];
        if ( typeof categoryToInteractExternalResourcesPath[categoryToInteractExternalResourcesPath.length-1] !== "undefined" )
            categoryToInteractExternalResourcesPath[categoryToInteractExternalResourcesPath.length-1] = input;
        else
            categoryToInteractExternalResourcesPath.push(input);
        setCategoryToInteract({ ...categoryToInteract, externalResourcesPath : categoryToInteractExternalResourcesPath });
    }

    let setCategoryToInteractSubtypeExternalResourcesPath = (id,input) => {
        let subcategories = categoryToInteract.subcategories;
        let subcategoryPaths = subcategories[id].externalResourcesPath || [];
        if ( typeof subcategoryPaths[subcategoryPaths.length-1] !== "undefined" )
            subcategoryPaths[subcategoryPaths.length-1] = input;
        else
            subcategoryPaths.push(input);
        subcategories[id].externalResourcesPath = subcategoryPaths;
        setCategoryToInteract({ ...categoryToInteract, subcategories : subcategories });
    }

    let addExternalPath = () => {
        let resourcePaths = categoryToInteract.externalResourcesPath;
        resourcePaths.push("");
        setCategoryToInteract({ ...categoryToInteract, externalResourcesPath : resourcePaths });
    }

    let wipeExternalPaths = () => {
        setCategoryToInteract({ ...categoryToInteract, externalResourcesPath : [] });
    }

    let removeLastPath = () => {
        let resourcePaths = categoryToInteract.externalResourcesPath;
        let newResources = [];
        for ( let i = 0 , l = resourcePaths.length - 2 ; i < l ; ++i )
            newResources[i] = resourcePaths[i];
        setCategoryToInteract({ ...categoryToInteract, externalResourcesPath : newResources });
    }

    let addSubtypeExternalPath = (id) => {
        let subcategories = categoryToInteract.subcategories;
        subcategories[id].externalResourcesPath.push("");
        setCategoryToInteract({ ...categoryToInteract, subcategories : subcategories });
    }

    let wipeSubtypeExternalPaths = (id) => {
        let subcategories = categoryToInteract.subcategories;
        subcategories[id].externalResourcesPath = [];
        setCategoryToInteract({ ...categoryToInteract, subcategories : subcategories });
    }

    let removeSubtypeLastPath = (id) => {
        let subcategories = categoryToInteract.subcategories;
        let newResources = [];
        for ( let i = 0 , l = subcategories[id].externalResourcesPath.length - 2 ; i < l ; ++i )
            newResources[i] = subcategories[id].externalResourcesPath[i];
        subcategories[id].externalResourcesPath = newResources;
        setCategoryToInteract({ ...categoryToInteract, subcategories : subcategories });
    }

        return (
            <div className="container-fluid" >

                <div>
                    <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                        <h1 className="h2">Categories<Button variant="primary" className="ml-4" onClick={() => openCreateCategoryModal()} >Create Category</Button></h1>
                    </div>

                    <div className={"container mt-5 mb-5" + ( errorCode === null && errorMessage === null ? " d-none" : "")}>
                        <div className="alert alert-danger" role="alert">{errorMessage}</div>
                    </div>

                    <div className="table-responsive">
                        <BootstrapTable data={categories} striped hover pagination search>
                            <TableHeaderColumn dataField='_id' isKey={ true } dataSort={ true }>ID</TableHeaderColumn>
                            <TableHeaderColumn dataField='name' dataSort={ true }>Name</TableHeaderColumn>
                            <TableHeaderColumn dataField='description' dataSort={ true }>Description</TableHeaderColumn>
                            <TableHeaderColumn dataField='externalResourcesPath' dataSort={ true }>Resource paths</TableHeaderColumn>
                            <TableHeaderColumn dataFormat={formatSubcategories} >Subcategories</TableHeaderColumn>
                            <TableHeaderColumn dataFormat={categoryOperations} >Operations</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>

                <Modal show={categoryToInteract} onHide={cancelCategoryInteraction}>
                    <Modal.Header closeButton>
                        {
                            isCreatingCategory && 
                            <Modal.Title>Create category</Modal.Title>
                        }
                        {
                            isUpdatingCategory && 
                            <Modal.Title>Update category {categoryToInteract ? "\"" + categoryToInteract.name + "\"" : ""}</Modal.Title>
                        }
                        {
                            isViewingSubcategories && 
                            <Modal.Title>View category {categoryToInteract ? "\"" + categoryToInteract.name + "\"" : ""} subcategories</Modal.Title>
                        }
                        {
                            isViewingCategory && 
                            <Modal.Title>View category {categoryToInteract ? "\"" + categoryToInteract.name + "\"" : ""}</Modal.Title>
                        }
                        {
                            isDeletingCategory && 
                            <Modal.Title>Delete category {categoryToInteract ? "\"" + categoryToInteract.name + "\"" : ""} ?</Modal.Title>
                        }
                    </Modal.Header>
                    <Modal.Body>

                        {
                            categoryToInteract && 
                            <>
                            {
                                isCreatingCategory && 
                                <>
                                    <Form.Group>
                                        <InputLabel className="text-dark" >Name</InputLabel>
                                        <Form.Control value={categoryToInteract.name} onChange={(e) => setCategoryToInteract({ ...categoryToInteract, name : e.target.value })} />
                                    </Form.Group>

                                    <Form.Group>
                                        <InputLabel className="text-dark" >Description</InputLabel>
                                        <Form.Control value={categoryToInteract.description} onChange={(e) => setCategoryToInteract({ ...categoryToInteract, description : e.target.value })} />
                                    </Form.Group>

                                    <Form.Group className="mt-6 mb-6" >
                                        <InputLabel className="text-dark" >Path of external resources</InputLabel>
                                        <Form.Control value={categoryToInteract.externalResourcesPath} readOnly />
                                        <div className="row">
                                            <div className="col-sm-8">
                                                <Form.Group>
                                                    <InputLabel className="text-dark" >Add external path</InputLabel>
                                                    <Form.Control value={categoryToInteract.externalResourcesPath[categoryToInteract.externalResourcesPath.length-1]} onChange={(e) => setCategoryToInteractExternalResourcesPath(e.target.value)} />
                                                </Form.Group>
                                            </div>
                                            <div className="col-sm-4">
                                                    <Button variant="success" onClick={() => addExternalPath()} className="mr-1" ><i className="fas fa-arrow-right"></i></Button>
                                                    <Button variant="danger" onClick={() => removeLastPath()} className="mr-1" ><i className="fas fa-minus"></i></Button>
                                                    <Button variant="danger" onClick={() => wipeExternalPaths()} className="mr-1" ><i className="fas fa-trash"></i></Button>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    
                                    {
                                        categoryToInteract.subcategories && categoryToInteract.subcategories.map((subcat,id) => 
                                        <Form className="mt-6 mb-6" >
                                            <Row>
                                                <Col>
                                                    <InputLabel className="text-dark" >Subcategory name</InputLabel>
                                                    <Form.Control value={subcat.name} onChange={(e) => setSubcategory(id,"name",e.target.value)} />
                                                </Col>
                                                <Col>
                                                    <InputLabel className="text-dark" >Subcategory description</InputLabel>
                                                    <Form.Control value={subcat.description} onChange={(e) => setSubcategory(id,"description",e.target.value)} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Form.Group className="mt-5 mb-5" >
                                                    <InputLabel className="text-dark" >Path of external resources</InputLabel>
                                                    <Form.Control value={subcat.externalResourcesPath} readOnly />
                                                    <div className="row">
                                                        <div className="col-sm-8">
                                                            <Form.Group>
                                                                <InputLabel className="text-dark" >Add external path</InputLabel>
                                                                <Form.Control value={subcat.externalResourcesPath[subcat.externalResourcesPath.length-1]} onChange={(e) => setCategoryToInteractSubtypeExternalResourcesPath(id,e.target.value)} />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <Button variant="success" onClick={() => addSubtypeExternalPath(id)} className="mr-1" ><i className="fas fa-arrow-right"></i></Button>
                                                            <Button variant="danger" onClick={() => removeSubtypeLastPath(id)} className="mr-1" ><i className="fas fa-minus"></i></Button>
                                                            <Button variant="danger" onClick={() => wipeSubtypeExternalPaths(id)} className="mr-1" ><i className="fas fa-trash"></i></Button>
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </Row>
                                        </Form>
                                        )
                                        
                                    }

                                    <Button variant="primary" className="ml-4" onClick={() => addSubcategory()} >Add subcategory</Button>
                                </>   
                            }
                            {
                                isUpdatingCategory && 
                                <>
                                    <Form.Group>
                                        <InputLabel className="text-dark" >Name</InputLabel>
                                        <Form.Control value={categoryToInteract.name} onChange={(e) => setCategoryToInteract({ ...categoryToInteract, name : e.target.value })} />
                                    </Form.Group>

                                    <Form.Group>
                                        <InputLabel className="text-dark" >Description</InputLabel>
                                        <Form.Control value={categoryToInteract.description} onChange={(e) => setCategoryToInteract({ ...categoryToInteract, description : e.target.value })} />
                                    </Form.Group>
                                    
                                    <Form.Group className="mt-6 mb-6" >
                                        <InputLabel className="text-dark" >Path of external resources</InputLabel>
                                        <Form.Control value={categoryToInteract.externalResourcesPath} readOnly />
                                        <div className="row">
                                            <div className="col-sm-8">
                                                <Form.Group>
                                                    <InputLabel className="text-dark" >Add external path</InputLabel>
                                                    <Form.Control value={categoryToInteract.externalResourcesPath[categoryToInteract.externalResourcesPath.length-1]} onChange={(e) => setCategoryToInteractExternalResourcesPath(e.target.value)} />
                                                </Form.Group>
                                            </div>
                                            <div className="col-sm-4">
                                                <Button variant="success" onClick={() => addExternalPath()} className="mr-1" ><i className="fas fa-arrow-right"></i></Button>
                                                <Button variant="danger" onClick={() => removeLastPath()} className="mr-1" ><i className="fas fa-minus"></i></Button>
                                                <Button variant="danger" onClick={() => wipeExternalPaths()} className="mr-1" ><i className="fas fa-trash"></i></Button>
                                            </div>
                                        </div>
                                    </Form.Group>

                                    {
                                        categoryToInteract.subcategories && categoryToInteract.subcategories.map((subcat,id) => 
                                        <Form className="mt-6 mb-6" >
                                            <Row>
                                                <Col>
                                                    <InputLabel className="text-dark" >Subcategory name</InputLabel>
                                                    <Form.Control value={subcat.name} onChange={(e) => setSubcategory(id,"name",e.target.value)} />
                                                </Col>
                                                <Col>
                                                    <InputLabel className="text-dark" >Subcategory description</InputLabel>
                                                    <Form.Control value={subcat.description} onChange={(e) => setSubcategory(id,"description",e.target.value)} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Form.Group className="mt-5 mb-5" >
                                                    <InputLabel className="text-dark" >Path of external resources</InputLabel>
                                                    <Form.Control value={subcat.externalResourcesPath} readOnly />
                                                    <div className="row">
                                                        <div className="col-sm-8">
                                                            <Form.Group>
                                                                <InputLabel className="text-dark" >Add external path</InputLabel>
                                                                <Form.Control value={subcat.externalResourcesPath[subcat.externalResourcesPath.length-1]} onChange={(e) => setCategoryToInteractSubtypeExternalResourcesPath(id,e.target.value)} />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <Button variant="success" onClick={() => addSubtypeExternalPath(id)} className="mr-1" ><i className="fas fa-arrow-right"></i></Button>
                                                            <Button variant="danger" onClick={() => removeSubtypeLastPath(id)} className="mr-1" ><i className="fas fa-minus"></i></Button>
                                                            <Button variant="danger" onClick={() => wipeSubtypeExternalPaths(id)} className="mr-1" ><i className="fas fa-trash"></i></Button>
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </Row>
                                        </Form>
                                        )
                                    }

                                    <Button variant="primary" className="ml-4" onClick={() => addSubcategory()} >Add subcategory</Button>
                                </>   
                            }
                            {
                                isViewingCategory && 
                                <>
                                    <Form.Group>
                                        <InputLabel className="text-dark" >Name</InputLabel>
                                        <Form.Control value={categoryToInteract.name} readOnly />
                                    </Form.Group>

                                    <Form.Group>
                                        <InputLabel className="text-dark" >Description</InputLabel>
                                        <Form.Control value={categoryToInteract.description} readOnly />
                                    </Form.Group>

                                    {
                                        categoryToInteract.subcategories && categoryToInteract.subcategories.map((subcat,id) => 
                                        <Form className="mt-6 mb-6" >
                                            <Row>
                                                <Col>
                                                    <InputLabel className="text-dark" >Subcategory name</InputLabel>
                                                    <Form.Control value={subcat.name} readOnly />
                                                </Col>
                                                <Col>
                                                    <InputLabel className="text-dark" >Subcategory description</InputLabel>
                                                    <Form.Control value={subcat.description} readOnly />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group className="mt-5 mb-5" >
                                                        <InputLabel className="text-dark" >Path of external resources</InputLabel>
                                                        <Form.Control value={subcat.externalResourcesPath} readOnly />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Form>
                                        )
                                    }
                                </>   
                            }
                            {
                                isViewingSubcategories && 
                                <>
                                    {
                                        categoryToInteract.subcategories && categoryToInteract.subcategories.map((subcat,id) => 
                                        <Form className="mt-5" >
                                            <Row>
                                                <Col>
                                                    <InputLabel className="text-dark" >Subcategory name</InputLabel>
                                                    <Form.Control value={subcat.name} readOnly />
                                                </Col>
                                                <Col>
                                                    <InputLabel className="text-dark" >Subcategory description</InputLabel>
                                                    <Form.Control value={subcat.description} readOnly />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group className="mt-5 mb-5" >
                                                        <InputLabel className="text-dark" >Path of external resources</InputLabel>
                                                        <Form.Control value={subcat.externalResourcesPath} readOnly />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Form>
                                        )
                                        
                                    }
                                </>
                            }
                            {
                                isDeletingCategory && 
                                <>
                                    Are you sure you want to delete this category ?
                                </>
                            }
                            </>
                        }


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="float-left" onClick={cancelCategoryInteraction}>Cancel</Button>
                        {
                            isCreatingCategory && 
                            <Button variant="success" className="float-left" onClick={createCategory}>Create</Button>
                        }
                        {
                            isUpdatingCategory && 
                            <Button variant="warning" className="float-left text-white" onClick={updateCategory}>Update</Button>
                        }
                        {
                            isDeletingCategory && 
                            <Button variant="danger" className="float-left" onClick={deleteCategory}>Delete</Button>
                        }
                    </Modal.Footer>
                </Modal>

            </div>
        );

}
 
export default Categories;
