const admin = 99;
const client = 1;

const API_URL = "https://api.blockadhoc.com";
const ADMIN_WEBSITE = "https://admin.blockadhoc.com";

const reqHeader = { "Access-Control-Allow-Origin" : "*" , "Content-Type" : "application/json" };
const reqHeaderFile = { "headers" : { "Access-Control-Allow-Origin": "*", 'Content-Type': 'multipart/form-data;' } };
const H64_API = "https://api.h64.fr";
const ERC20_DEPLOY_URL = "/v1/deployContracts/deployERC20ContractWithCoinbase";
const VOUCHERS_DEPLOY_URL = "/v1/deployContracts/deployVouchers";
const BOOKS_DEPLOY_URL = "/v1/deployContracts/deployBooks";

const hostContainer = {
    "name" : "",
    "description" : "",
    "links" : [""],
};

const clientContainer = {
    "name" : "",
    "description" : "",
    "links" : [""],
    "hosts" : [hostContainer]
}

const protocolContainer = {
    "name" : "",
    "description" : "",
    "links" : [""],
    "clients" : [clientContainer]
};

const keyword = {
    "category" : "Untitled*",
    "keywords" : [],
    "forContracts" : false,
    "forNodes" : false,
    "forBlockchains" : false,
    "keywordName" : ""
};

module.exports.API_URL = API_URL;
module.exports.admin = admin;
module.exports.reqHeader = reqHeader;
module.exports.H64_API = H64_API;
module.exports.ERC20_DEPLOY_URL = ERC20_DEPLOY_URL;
module.exports.VOUCHERS_DEPLOY_URL = VOUCHERS_DEPLOY_URL;
module.exports.BOOKS_DEPLOY_URL = BOOKS_DEPLOY_URL;
module.exports.client = client;
module.exports.reqHeaderFile = reqHeaderFile;
module.exports.protocolContainer = protocolContainer;
module.exports.clientContainer = clientContainer;
module.exports.hostContainer = hostContainer;
module.exports.ADMIN_WEBSITE = ADMIN_WEBSITE;
module.exports.keyword = keyword;