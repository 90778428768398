import React, { useState , useEffect } from 'react';
import Request from './Config/Request.js';
import Parameters from './Config/Parameters.js';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import InputLabel from '@material-ui/core/InputLabel';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

function H64Users() {

    let [errorCode,setErrorCode] = useState(null);
    let [errorMessage,setErrorMessage] = useState(null);
    let [h64Users,setH64Users] = useState([]);
    let [user,setUser] = useState(JSON.parse(sessionStorage.getItem('user')));
    let [userToInteract,setUserToInteract] = useState(null);
    let [isViewing,setView] = useState(false);
    let [isUpdating,setUpdate] = useState(false);
    let [isDeleting,setDelete] = useState(false);

    let getAllH64Users = async () => {
        try{
            let users = await Request.h64HttpRequest("PUT","/v1/account/listAll",{ password : user.passwordRaw , accountID : user.h64AccountID });
            return users.result || [];
        }catch(err){
            console.error(err);
            return [];
        }
    }

    useEffect( () => {
        try{
            const fetchData = async () =>  {
                const result =  await getAllH64Users();
                console.log("We set the users >> " + JSON.stringify(result));
                setH64Users(result);
            }
            fetchData();
        }catch(err){
            console.error(err);
        }
      },[]);

    const options = {
        clickToEdit: true,
        clickToSelect: true,
    };

    let updateUser = async (row) => {
        setUserToInteract(row);
        setUpdate(true);
    }

    let submitUpdateUser = () => {
        console.log("Here we update the user");
    }

    let viewUser = (row) => {
        setUserToInteract(row);
        setView(true);
    }

    let hideUser = () => {
        setView(false);
        setUpdate(false);
        setDelete(false);
        setUserToInteract(null);
    }

    let deleteUser = (row) => {
        setUserToInteract(row);
        setDelete(true);
    }

    let submitDeleteUser = async () => {
        try{
            let deleteTheUser = await Request.httpRequest("DELETE","/deleteUser",{ userId : userToInteract._id });
            if ( deleteTheUser ) {
                if ( deleteTheUser.errorCode !== undefined && deleteTheUser.errorMessage !== undefined ) {
                    setErrorCode(deleteTheUser.errorCode);
                    setErrorMessage(deleteTheUser.errorMessage);
                    if ( deleteTheUser.errorCode === null && deleteTheUser.errorMessage === null ) {
                        setH64Users(h64Users.filter((user) => user.email !== userToInteract.email ));
                        setUserToInteract(null);
                        setDelete(false);
                    }
                }
            }
        }catch(err){
            console.error(err);
            return [];
        }
    }

    let handlerEmail = (e) => {
        setUserToInteract({...userToInteract, email : e.target.value });
        setUpdate(true);
    }

    let handlerName = (e) => {
        setUserToInteract({...userToInteract, name : e.target.value });
        setUpdate(true);
    }

    let buttons = (cell, row) => {
        return <>
            <Button variant="primary" className="mr-2" onClick={() => viewUser(row) } >View</Button>
            <Button variant="warning" className="mr-2" onClick={() => updateUser(row) } >Update</Button>
            {
                row.group !== Parameters.admin && 
                <Button variant="danger" onClick={() => deleteUser(row) } >Delete</Button>
            }
        </>;
      }

    let getMomentCreationDate = (cell, row) => {
        return <>{moment(row.creationDate).format("L HH:mm:ss")}</>
    }

    return (
        <>

            <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                <h1 className="h2">All H64 users</h1>
            </div>

            <div className={"container mt-5 mb-5" + ( errorCode === null && errorMessage === null ? " d-none" : "")}>
                <div className="alert alert-danger" role="alert">{errorMessage}</div>
            </div>

            <BootstrapTable data={h64Users} keyField='email' striped hover pagination search options={ options } >
                <TableHeaderColumn dataFormat={getMomentCreationDate} dataSort={ true }>Creation Date</TableHeaderColumn>
                <TableHeaderColumn dataField='email' dataSort={ true }>Email</TableHeaderColumn>
                <TableHeaderColumn dataField='name' dataSort={ true }>Full Name</TableHeaderColumn>
                <TableHeaderColumn dataFormat={buttons} dataSort={ true }>Operations</TableHeaderColumn>
            </BootstrapTable>

            {
                userToInteract !== null && 
                <>
                    <Modal show={isViewing} onHide={hideUser}>
                        <Modal.Header closeButton>
                            <Modal.Title>User ID #{userToInteract._id}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Tokeo / H64 account" checked={userToInteract.isTokeo} readOnly />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >Account address</InputLabel>
                                <Form.Control value={userToInteract.accountAddress} readOnly />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >Email</InputLabel>
                                <Form.Control value={userToInteract.email} readOnly />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >Name</InputLabel>
                                <Form.Control value={userToInteract.name} readOnly />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >Creation Date</InputLabel>
                                <Form.Control value={moment(userToInteract.creationDate).format("L HH:mm:ss")} readOnly />
                            </Form.Group>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" className="float-left" onClick={hideUser}>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={isDeleting} onHide={hideUser}>
                        <Modal.Header closeButton>
                            <Modal.Title>You're about to delete a user...</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <InputLabel className="text-dark" >Are you sure you want to delete {userToInteract.email} ?</InputLabel>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" className="float-left" onClick={hideUser}>
                                Cancel
                            </Button>
                            <Button variant="danger" className="text-light float-right" onClick={submitDeleteUser} >
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={isUpdating} onHide={hideUser}>
                        <Modal.Header closeButton>
                            <Modal.Title>User ID #{userToInteract._id}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <Form.Group>
                                <InputLabel className="text-dark" >Email</InputLabel>
                                <Form.Control value={userToInteract.email} onChange={handlerEmail} />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >Name</InputLabel>
                                <Form.Control value={userToInteract.name} onChange={handlerName} />
                            </Form.Group>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" className="float-left" onClick={hideUser}>
                                Cancel
                            </Button>
                            <Button variant="warning" className="text-light float-right" onClick={submitUpdateUser} >
                                Update
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            }

        </>
    );
}

export default H64Users;
