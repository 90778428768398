import React, { useState , useEffect } from 'react';
import Request from './Config/Request.js';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputLabel from '@material-ui/core/InputLabel';
import Button from 'react-bootstrap/Button';

function MyApplications() {

    let [errorCode,setErrorCode] = useState(null);
    let [errorMessage,setErrorMessage] = useState(null);
    let [applications,setApplications] = useState([]);
    let [user,setUser] = useState(JSON.parse(sessionStorage.getItem('user')));
    let [app,setApp] = useState(false);
    let [newApp,setNewApp] = useState(false);
    let [isCreating,setCreating] = useState(false);
    let [isDeleting,setDeleting] = useState(false);
    let [isUsingApp,setUsingApp] = useState(false);
    let [isUpdating,setUpdating] = useState(false);

    let getApps = async () => {
        let apps = await Request.h64HttpRequest("GET","/getAllApplications",{});
        if ( apps ){
            setErrorCode(apps.errorCode);
            setErrorMessage(apps.errorMessage);
        }
        return apps.applications;
    }

    useEffect( () => {
       const fetchData = async () =>  {
            const result =  await getApps();
            setApplications(result);
       }
        fetchData();
     },[]);


    let hideCreatingApp = () => {
        setApp(null);
        setCreating(false);
    }

    let createApp = async () => {
        try{
            let createApp = await Request.h64HttpRequest("POST","/addAccountApplication",{ accountID : user.h64AccountID , applicationId : app.applicationId , applicationName : app.applicationName });
            setErrorCode(createApp.errorCode);
            setErrorMessage(createApp.errorMessage);
            if ( createApp.errorCode === null && createApp.errorMessage === null ){
                let apps = applications;
                apps.push({ applicationId : app.applicationId , applicationName : app.applicationName });
                setApplications(apps);
            }
            setApp(false);
            setCreating(false);
        }catch(err){
            console.error(err);
        }
    }

    let deleteApp = (app) => {
        setApp(app);
        setDeleting(true);
    }

    let markApp = (app) => {
        setApp(app);
        setUsingApp(true);
    }

    let update = (app) => {
        setApp(app);
        setNewApp(app);
        setUpdating(true);
    }

    let operations = (cell, row) => {
        return <>
            <Button variant="danger" className="mr-2" onClick={() => deleteApp(row)} >Delete</Button>
            <Button variant="primary" className="mr-2" onClick={() => markApp(row)} >Use</Button>
            <Button variant="warning" className="mr-2 text-light" onClick={() => update(row)} >Update</Button>
        </>
    }

    let create = () => {
        setApp({ applicationId : "" , applicationName : "" });
        setCreating(true);
    }

    let hideDeleteApp = () => {
        setApp(null);
        setDeleting(false);
    }

    let deleteAppNow = async () => {
        try{
            let deleteApp = await Request.h64HttpRequest("DELETE","/deleteApplication",{ 
                applicationId : app._id 
            });
            setErrorCode(deleteApp.errorCode);
            setErrorMessage(deleteApp.errorMessage);
            if ( deleteApp.errorCode === null && deleteApp.errorMessage === null ){
                let apps = [];
                for ( let i = 0 , l = applications.length ; i < l ; ++i )
                    if ( applications[i]._id !== app._id )
                        apps.push(applications[i]);
                setApplications(apps);
            }
            setApp(false);
            setDeleting(false);
        }catch(err){
            console.error(err);
        }
    }

    let hideUsingApp = () => {
        setApp(false);
        setUsingApp(false);   
    }

    let hideUpdating = () => {
        setApp(false);
        setNewApp(false);
        setUpdating(false);
    }

    let getOwnerName = (cell, row) => {
        return <>{row.owner.name}</>
    }

    let updateApp = async () => {
        try{
            let update = await Request.h64HttpRequest("PATCH","/updateApplication",{
                applicationId : app._id , 
                newApplicationName : newApp.name 
            });
            console.log("Updated : " + JSON.stringify(update));
            setErrorCode(update.errorCode);
            setErrorMessage(update.errorMessage);
            if ( update.errorCode === null && update.errorMessage === null ){
                let apps = applications;
                for ( let i = 0 , l = apps.length ; i < l ; ++i )
                    if ( apps[i]._id === app._id )
                        apps[i].name = newApp.name;
                setApplications(apps);
            }
            setApp(false);
            setNewApp(false);
            setDeleting(false);
        }catch(err){
            console.error(err);
        }
    }

        return (
            <div className="container-fluid" >
            <div>
                <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                    <h1 className="h2">Applications
                    <button type="button" onClick={create} className="btn btn-primary text-light ml-2 mr-2">Create application</button>
                    </h1>
                </div>

                <div className={"container mt-5 mb-5" + ( errorCode === null && errorMessage === null ? " d-none" : "")}>
                    <div className="alert alert-danger" role="alert">{errorMessage}</div>
                </div>

                <div className="table-responsive">
                    <BootstrapTable data={applications} striped hover pagination search>
                        <TableHeaderColumn dataField='_id' isKey={true} dataSort={ true }>ID</TableHeaderColumn>
                        <TableHeaderColumn dataFormat={getOwnerName} dataSort={ true }>Owner</TableHeaderColumn>
                        <TableHeaderColumn dataField='name' dataSort={ true }>Project name</TableHeaderColumn>
                        <TableHeaderColumn dataFormat={operations} >Operations</TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div>
            {
                app && 
                <>
                    <Modal show={isCreating} onHide={hideCreatingApp}>
                        <Modal.Header closeButton>
                            <Modal.Title>Please complete the fields</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                        <Form.Group controlId="formBasicCheckbox">
                            <InputLabel className="text-dark" >Application ID</InputLabel>
                            <Form.Control value={app.applicationId} onChange={(e) => setApp({...app, applicationId : e.target.value }) } />
                        </Form.Group>

                        <Form.Group>
                            <InputLabel className="text-dark" >Application Name</InputLabel>
                            <Form.Control value={app.applicationName} onChange={(e) => setApp({...app, applicationName : e.target.value }) } />
                        </Form.Group>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" className="float-left" onClick={hideCreatingApp}>Cancel</Button>
                            <Button variant="success" className="text-light" onClick={createApp} >Create</Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={isUpdating} onHide={hideUpdating}>
                        <Modal.Header closeButton>
                            <Modal.Title>Please complete the fields</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                        <Form.Group>
                            <InputLabel className="text-dark" >Project Name</InputLabel>
                            <Form.Control value={newApp.name} onChange={(e) => setNewApp({...newApp, name : e.target.value }) } />
                        </Form.Group>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" className="float-left" onClick={hideUpdating}>Cancel</Button>
                            <Button variant="warning" className="text-light" onClick={updateApp} >Update</Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={isDeleting} onHide={hideDeleteApp}>
                        <Modal.Header closeButton>
                            <Modal.Title>You're about to delete an application</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                        <Form.Group controlId="formBasicCheckbox">
                            <InputLabel className="text-dark" >Are you sure you want to delete {app.applicationName} ?</InputLabel>
                        </Form.Group>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" className="float-left" onClick={hideDeleteApp}>Cancel</Button>
                            <Button variant="danger" className="text-light" onClick={deleteAppNow} >Delete</Button>
                        </Modal.Footer>
                    </Modal>
                </>
            }

        </div>
        );

}
 
export default MyApplications;
