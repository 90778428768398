import React, { useState , useEffect } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import Request from './Config/Request.js';
import Parameters from './Config/Parameters.js';
import getKeywords from './Common/getKeywords.js';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import SaveIcon from '@material-ui/icons/Save';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import MuiAlert from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';


function AlertMUI(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    }
}))(MuiDialogActions);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}
  
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
}));

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

function Keywords() {

    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);
    let [errorCode,setErrorCode] = useState(null);
    let [errorMessage,setErrorMessage] = useState(null);
    let [user,setUser] = useState(JSON.parse(sessionStorage.getItem('user')));
    let [keywords,setKeywords] = useState([]);
    let [keywordToHandle,setKeywordToHandle] = useState(null);
    let history = useHistory();
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [transition, setTransition] = useState(undefined);
    let [operationOutput,setOperationOutput] = useState(null);
  
    const handleClickSnackbar = (Transition) => () => {
      setTransition(() => Transition);
      setOpenSnackBar(true);
    };
  
    const handleCloseSnackbar = () => {
        setOpenSnackBar(false);
        setOperationOutput(null);
    };

    const handleClickOpen = (category,i) => {
        setKeywordToHandle({ ...category, index : i });
    };

    const handleClickSettings = (category,i) => {
        setKeywordToHandle({ ...category, index : i });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        let index = keywordToHandle.index;
        let _keywordToHandle = keywordToHandle;
        delete _keywordToHandle.index;
        setKeywordToHandle(null);
        setKeywords(keywords.map((keyword,_index) => {
            if ( _index === index )
                return _keywordToHandle;
            return keyword;
        }));
    };
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    useEffect( () => {
        const fetchData = async () =>  {
            const theKeywords =  await getKeywords();
            setKeywords(theKeywords);
        }
        fetchData();
    },[]);

    let submitCategory = async (categ,id) => {
        let addKeywords = await Request.httpRequest("POST","/addCategory",{
            category : categ ,
            email : user.email ,
            password : user.passwordRaw
        });
        if ( addKeywords )
            if ( addKeywords.errorCode !== undefined && addKeywords.errorMessage !== undefined )
                if ( addKeywords.errorCode === null && addKeywords.errorMessage === null ){
                    setOperationOutput({ message : "Changes have been successfully performed" , status : "success" });
                    setKeywords(keywords.map((keyword,_id) => {
                        if ( _id === id )
                            keyword._id = addKeywords.category._id;
                        return keyword;
                    }))
                }else
                    setOperationOutput({ message : addKeywords.errorMessage , status : "error" });   
    }

    let deleteCategory = async (categ) => {
        let deleteCategory = await Request.httpRequest("DELETE","/removeCategory",{
            category : categ ,
            email : user.email ,
            password : user.password
        });
        if ( deleteCategory )
            if ( deleteCategory.errorCode !== undefined && deleteCategory.errorMessage !== undefined )
                if ( deleteCategory.errorCode === null && deleteCategory.errorMessage === null ){
                    setKeywords(keywords.filter(keyword => keyword._id !== categ._id));
                    setOperationOutput({ message : "Changes have been successfully performed" , status : "success" });
                }else
                    setOperationOutput({ message : deleteCategory.errorMessage , status : "error" });   
    }

    const handleDeleteKeyword = (category,id,keyId) => {
        let theKeywords = JSON.parse(JSON.stringify(keywords));
        for ( let i = 0 , l = theKeywords.length ; i < l ; ++i )
            if ( i === id ){
                let newKeywords = [];
                for ( let j = 0 , k = theKeywords[i].keywords.length ; j < k ; ++j )
                    if ( j !== keyId )
                        newKeywords.push(theKeywords[i].keywords[j]);
                theKeywords[i].keywords = newKeywords;
            }
        setKeywords(theKeywords);
    };

    let handleAddKeyword = (id) => {
        let theKeywords = JSON.parse(JSON.stringify(keywords));
        for ( let i = 0 , l = theKeywords.length ; i < l ; ++i )
            if ( i === id ){
                theKeywords[id].keywords.push(theKeywords[id].keywordName);
                theKeywords[id].keywordName = "";
            }
        setKeywords(theKeywords);
    }

    return (
        <>

            <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                <h1 className="h2">Keywords</h1>
            </div>

            <div className={"container mt-5 mb-5" + ( errorCode === null && errorMessage === null ? " d-none" : "")}>
                <div className="alert alert-danger" role="alert">{errorMessage}</div>
            </div>

            <div className="row justify-content-center">
                <Button variant="contained" color="primary" size="large" onClick={() => setKeywords([ ...keywords, ...[Parameters.keyword] ])} >
                    Add category
                </Button>
            </div>

            {
                keywords.length > 0 ? (
                    <div className={classes.root + " mt-5"}>
                        <AppBar position="static" color="default">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="on"
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                {
                                    keywords.map((category,id) => 
                                            <Tab 
                                                label={category.category} 
                                                {...a11yProps(id)} 
                                                onClick={() => handleClickOpen(category,id)} 
                                                key={id}
                                            />
                                    )
                                }
                            </Tabs>
                        </AppBar>

                        {
                            keywords.map((category,id) => 
                                <TabPanel value={value} index={id} key={id} className="container" >

                                    <h5 className="mt-5">Operations</h5>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-6 justify-content-center text-center">
                                            <Button
                                                variant="contained"
                                                color="default"
                                                onClick={() => handleClickSettings(category,id)}
                                                startIcon={<SettingsIcon>Configuration</SettingsIcon>}
                                            >
                                                Configuration
                                            </Button>
                                        </div>
                                        <div className="col-sm-6 justify-content-center text-center">
                                            <Button 
                                                variant="contained" 
                                                color="default" 
                                                onClick={() => submitCategory(category,id)} 
                                                startIcon={<SaveIcon>Submit</SaveIcon>}
                                            >
                                                Submit changes
                                            </Button>
                                        </div>

                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-sm-6 justify-content-center text-center">
                                            <Button 
                                                variant="contained" 
                                                color="secondary" 
                                                onClick={() => deleteCategory(category)}
                                                startIcon={<DeleteIcon>Delete</DeleteIcon>}
                                            >
                                                Delete category
                                            </Button>
                                        </div>
                                        <div className="col-sm-6 justify-content-center text-center">
                                            <FormControl>
                                                <InputLabel htmlFor="standard-adornment-password" className="mb-3" >Input keyword</InputLabel>
                                                <Input
                                                    id="standard-adornment-password"
                                                    type="text"
                                                    value={category.keywordName}
                                                    onChange={(e) => 
                                                        setKeywords(keywords.map((keyword,_id) => {
                                                            if ( id === _id )
                                                                keyword.keywordName = e.target.value;
                                                            return keyword;
                                                        })
                                                    )}
                                                />
                                            </FormControl>
                                            <div className="row justify-content-center text-center mt-3">
                                                <Button
                                                    aria-label="Add keyword to category"
                                                    variant="contained" 
                                                    color="primary" 
                                                    size="large"
                                                    onClick={() => handleAddKeyword(id)}
                                                >
                                                    Add keyword
                                                </Button>
                                            </div>
                                        </div>
                                    </div>

                                    <h5 className="mt-5">Keywords</h5>
                                    <hr/>
                                    <div className="row mt-3 justify-content-center text-center">
                                        {
                                            category.keywords.map((keyword,keyId) => 
                                                <div key={keyId}>
                                                    {
                                                        keyword && 
                                                        <Chip
                                                            size="large"
                                                            color="primary" 
                                                            label={keyword}
                                                            onDelete={() => handleDeleteKeyword(category,id,keyId)}
                                                            className="m-3"
                                                        />
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </TabPanel>
                            )
                        }
                    </div>
                ) : (
                    <div className="container mt-5">
                        <Alert variant="filled" severity="info">
                            No categories found
                        </Alert>
                    </div>
                )
            }

            {
                keywordToHandle && 
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        Configure category
                    </DialogTitle>
                    <DialogContent dividers>
                        <div className="container p-5 justify-content-center text-center">
                            <div className="row">
                                <TextField id="outlined-basic" label="Category name" value={keywordToHandle.category} onChange={(e) => setKeywordToHandle({ ...keywordToHandle , category : e.target.value })} variant="outlined" />
                            </div>
                            <div className="row mt-4">
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={keywordToHandle.forContracts}
                                        onChange={() => setKeywordToHandle({ ...keywordToHandle, forContracts : !keywordToHandle.forContracts })}
                                        color="primary"
                                    />
                                    }
                                    label="Available for smart contracts"
                                />
                            </div>
                            <div className="row mt-4">
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={keywordToHandle.forNodes}
                                        onChange={() => setKeywordToHandle({ ...keywordToHandle, forNodes : !keywordToHandle.forNodes })}
                                        color="primary"
                                    />
                                    }
                                    label="Available for blockchain nodes"
                                />
                            </div>
                            <div className="row mt-4">
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={keywordToHandle.forBlockchains}
                                        onChange={() => setKeywordToHandle({ ...keywordToHandle, forBlockchains : !keywordToHandle.forBlockchains })}
                                        color="primary"
                                    />
                                    }
                                    label="Available for blockchain"
                                />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="p-5">
                        <div className="row text-center justify-content-center">
                            <Button autoFocus onClick={handleClose} color="primary">
                                Save changes
                            </Button>  
                        </div>
                    </DialogActions>
                </Dialog>
            }

            {
                operationOutput && 
                <Snackbar
                    open={operationOutput !== null}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={5000}
                    TransitionComponent={transition}
                    key={transition ? transition.name : ''}
                >
                    <AlertMUI severity={operationOutput.status}>{operationOutput.message}</AlertMUI>
                </Snackbar>
            }

        </>
    );

}


export default Keywords;