import React , { useState , useEffect } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { Link } from 'react-router-dom';
import Request from './Config/Request.js';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import SCConstructor from './SmartContractConstructorModal.js';
import CodeButton from './SmartContractCode.js';
import Popper, { PopperPlacementType } from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

function SmartContracts(props) {
    const scDescriptionMinLength = 5;
    const scTitleMinLength = 3;
    let [smartContracts,setSmartContracts] = useState([]);
    let [errorCode,setErrorCode] = useState(null);
    let [errorMessage,setErrorMessage] = useState(null);
    let [smartContract,setSmartContract] = useState(null);
    let [isDeleting,setDeleting] = useState(false);
    let [user,setUser] = useState(JSON.parse(sessionStorage.getItem('user')));
    let [constructor,setConstructor] = useState(null);
    let [popperOpen, setPopperOpen] = useState(false);
    let [popperPlacement, setPopperPlacement] = useState();
    let [popperAnchorEl, setPopperAnchorEl] = useState(null);
    let [popperContent, setPopperContent] = useState([]);
    let [visibility, setVisibility] = useState(null);
    let [initialSmartContracts, setInitialSmartContracts] = useState([]);
    let [type, setType] = useState(null);
    let [filters, setFilters] = useState({});
    const classes = useStyles();

    let getSmartContracts = async () => {
        let smartContracts = await Request.h64HttpRequest("GET","/getAllDemands",{});
        return smartContracts.smartContracts || [];
    }

    const options = {
        clickToSelect: true
    };

    let handlePopperClick = (newPlacement: PopperPlacementType,content) => (
        event: React.MouseEvent<HTMLButtonElement>,
      ) => {
            setPopperAnchorEl(event.currentTarget);
            setPopperOpen((prev) => popperPlacement !== newPlacement || !prev);
            setPopperPlacement(newPlacement);
            setPopperContent(content);
      };

    let hideDeleteModal = () => {
        setDeleting(false);
        setSmartContract(null);
    }

    let filterListByType = (type) => {
        let typeToAdd = null;
        switch ( type ){
            case "all":
                typeToAdd = null;
            break;

            case "core":
                typeToAdd = true;
            break;

            case "plugin":
                typeToAdd = false;
            break;
        }
        setFilters({ ...filters , isCore : typeToAdd });
        let theFilters = { ...filters , isCore : typeToAdd };

        setSmartContracts(initialSmartContracts.filter((sc) => {
            let filterOK = true;
            for ( let i in theFilters )
                if ( theFilters[i] !== null && theFilters[i] !== sc[i] )
                    filterOK = false;
            if ( filterOK )
                return sc;
        }));
    }

    let filterListByVisibility = (visibility) => {
        let visibilityToAdd = null;
        switch ( visibility ){
            case "all":
                visibilityToAdd = null;
            break;

            case "public":
                visibilityToAdd = true;
            break;

            case "private":
                visibilityToAdd = false;
            break;
        }
        setFilters({ ...filters , isPublic : visibilityToAdd });
        let theFilters = { ...filters , isPublic : visibilityToAdd };
        
        setSmartContracts(initialSmartContracts.filter((sc) => {
            let filterOK = true;
            for ( let i in theFilters )
                if ( theFilters[i] !== null && theFilters[i] !== sc[i] )
                    filterOK = false;
            if ( filterOK )
                return sc;
        }));
    }

    useEffect( () => {
        try{
            const fetch = async () => {
                let contracts = await getSmartContracts();
                setSmartContracts(contracts);
                setInitialSmartContracts(contracts);
            }
            fetch();
        }catch(err){
            console.error(err);
        }
    },[]);

    let deleteSC = (row) => {
        setSmartContract(row);
        setDeleting(true);
    }

    let showConstructor = (sc) => {
        setConstructor(sc.constructorParameters);
    }

    let showCode = (sc) => {
        try {
            return <CodeButton 
                        classInjector={"btn-mini"} 
                        bundleTitle={sc.title}
                        bundleDescription={sc.description}
                        smartContracts={sc.smartContracts} 
                        templateId={sc._userAccountId} 
                        creationDate={new Date(sc.creationDate).getTime()} 
                        bundleId={sc._id} 
                    ></CodeButton>;
        } catch (err) {
            console.error(err);
            return <></>;
        }
    }

    let generatePluginsData = (plugins) => {
        let toReturn = [];
        console.log("The plugins are : " + plugins);
        if ( plugins )
            for ( let j = 0 , k = plugins.length ; j < k ; ++j )
                for ( let i = 0 , l = smartContracts.length ; i < l ; ++i ){
                    console.log(String(smartContracts[i]._id) + " === " + String(plugins[j]));
                    if ( String(smartContracts[i]._id) === String(plugins[j]) ){
                        console.log("We're good !!");
                        toReturn.push(smartContracts[i]);
                    }
                }
        return toReturn;
    }

    let getScStatus = (cell, row) => {
        if ( row.isPublic )
            return <>PUBLIC</>;
        return <>PRIVATE</>;
    }

    let getScRole = (cell, row) => {
        if ( row.isCore )
            return <>CORE CONTRACT</>;
        return <>PLUGIN CONTRACT</>;
    }

    let deleteSmartContract = async () => {
        try{
            let deleteSC = await Request.h64HttpRequest("DELETE","/deleteSmartContract/" + smartContract._id,{});
            setErrorCode(deleteSC.errorCode);
            setErrorMessage(deleteSC.errorMessage);
            if ( deleteSC.errorCode === null && deleteSC.errorMessage === null ){
                let contracts = [];
                for ( let i = 0 , l = smartContracts.length ; i < l ; ++i )
                    if ( smartContracts[i]._id !== smartContract._id )
                        contracts.push(smartContracts[i]);
                setSmartContracts(contracts);
                setDeleting(false);
                setSmartContract(null);
            }
        }catch(err){
            console.error(err);
        }
    }

    let getBlockchainCreationDate = (cell, row) => {
        return <>{moment(row.creationDate).format("L HH:mm:ss")}</>
    }

    let hideConstructor = () => {
        setConstructor(null);
    }

        return (
            <>

                <Popper open={popperOpen} anchorEl={popperAnchorEl} placement={popperPlacement} transition>
                    {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                        <div className="container p-3">
                            <h4>For plugins</h4>
                            <div>
                                {
                                    popperContent.map((plugin,id) => 
                                        <p key={id} >Smart contract "{plugin.title}" : {plugin.description}</p>
                                    )
                                }
                            </div>
                        </div>
                        </Paper>
                    </Fade>
                    )}
                </Popper>

                <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                    <h1 className="h2">
                        Smart Contracts Library
                        <Link to={"/handleSmartContract/deployTemplate"} className="btn btn-primary ml-4 text-white" >Create SC template</Link>
                    </h1>
                </div>

                <div className={"container mt-5 mb-5" + ( errorCode === null && errorMessage === null ? " d-none" : "")}>
                    <div className="alert alert-danger" role="alert">{errorMessage}</div>
                </div>

                <div className="container">
                    <div className="row text-center">
                        <div className="col-sm-6">
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Visibility</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={filters.visibility}
                                onChange={(e) => filterListByVisibility(e.target.value)}
                                >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={"public"}>Public</MenuItem>
                                <MenuItem value={"private"}>Private</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-sm-6">
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={filters.type}
                                onChange={(e) => filterListByType(e.target.value)}
                                >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={"core"}>Core</MenuItem>
                                <MenuItem value={"plugin"}>Plugin</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>

                <BootstrapTable data={smartContracts} keyField='_id' striped hover pagination search options={ options } >
                    <TableHeaderColumn dataField='title' dataSort={ true } >Title</TableHeaderColumn>
                    <TableHeaderColumn dataField='description' dataSort={ true } >Description</TableHeaderColumn>
                    <TableHeaderColumn dataField='type' dataSort={ true } >Category</TableHeaderColumn>
                    <TableHeaderColumn dataField='subtype' dataSort={ true } >Subcategory</TableHeaderColumn>
                    <TableHeaderColumn dataFormat={getScStatus} >Status</TableHeaderColumn>
                    <TableHeaderColumn dataFormat={getScRole} >Role</TableHeaderColumn>
                    <TableHeaderColumn dataFormat={getBlockchainCreationDate} dataField='creationDate' dataSort={ true }>Creation Date</TableHeaderColumn>
                </BootstrapTable>


                {
                    constructor && 
                    <Modal show={constructor} onHide={hideConstructor}>
                        <Modal.Header closeButton>
                            <Modal.Title>Smart Contract Constructor</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                        {
                            typeof constructor !== "undefined" && constructor && constructor.map((cdp,cdpid) =>
                                <Form.Group>
                                    <InputLabel className="text-dark" >{cdp.codeName}</InputLabel>
                                    <Form.Control value={cdp.value} readOnly />
                                </Form.Group>
                            )
                        }

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" className="float-left" onClick={hideConstructor}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>
                }


                {
                    smartContract && 
                        <Modal show={isDeleting} onHide={hideDeleteModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Smart Contract ID #{smartContract._id}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                Are you sure you want to delete this smart contract ?

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className="float-left" onClick={hideDeleteModal}>Cancel</Button>
                                <Button variant="danger" className="text-light" onClick={deleteSmartContract} >Delete</Button>
                            </Modal.Footer>
                        </Modal>
                }

            </>
        );
}

export default SmartContracts;
